import { useHotkeys } from 'react-hotkeys-hook';

/**
   A common hook to trigger the useHotkeys hook on keyup.
 */
export const useHotKeysOnKeyup = (keys: string, callback: (event: KeyboardEvent) => void, deps?: any[]) => {
  useHotkeys(
    keys,
    callback,
    {
      // We're using 'keyup' here instead of 'keydown' because callback gets triggered continuously until key is pressed.
      keyup: true,
    },
    deps,
  );
};
