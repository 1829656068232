import { useDispatch, useSelector } from 'react-redux';

import { STATIC_MESSAGES } from 'components/constants';
import { HigherBidModal } from 'components/right-panel/pass-sell-lot/higher-bid-modal';
import { BID_TYPES } from 'components/right-panel/types';
import { useModal } from 'hooks/useModal';
import { mutateLot, mutateSell } from 'redux/middlewares/live-server/actions';
import { brokerActions } from 'redux/slices/broker';
import {
  selectCurrentAuctionState,
  selectCurrentBid,
  selectSubmittedBid,
  selectSubmittedBidType,
} from 'redux/slices/broker/selectors';
import { AUCTION_STATES } from 'redux/slices/broker/types';
import { selectCurrentLot } from 'redux/slices/catalog/selectors';

import { ILotHoverProps } from './types';

import styles from './lot-card.module.scss';

export function LotHover({ lotNumber }: ILotHoverProps) {
  const dispatch = useDispatch();
  const currentLot = useSelector(selectCurrentLot);
  const currentBid = useSelector(selectCurrentBid);
  const auctionState = useSelector(selectCurrentAuctionState);
  const submittedBid = useSelector(selectSubmittedBid);
  const submittedBidType = useSelector(selectSubmittedBidType);
  const showPassAndSellLotBtns = +currentBid > 0 && !currentLot?.isSold;
  const {
    isOpenModal: isHigherBidModalOpen,
    openModal: openHigherBidModal,
    closeModal: closeHigherBidModal,
  } = useModal();

  const handleSell = () => {
    dispatch(mutateSell({ nextAction: mutateLot(lotNumber), triggerNextActionOnSuccessfulSell: true }));
  };

  const handlePassOpenLot = () => {
    if (auctionState === AUCTION_STATES.PAUSED) {
      dispatch(brokerActions.displayCustomMessage({ message: STATIC_MESSAGES.START_AUCTION_FIRST }));
      return;
    }
    dispatch(mutateLot(lotNumber));
  };

  const handleSellOpen = () => {
    if (auctionState === AUCTION_STATES.PAUSED) {
      dispatch(brokerActions.displayCustomMessage({ message: STATIC_MESSAGES.START_AUCTION_FIRST }));
    } else if (submittedBid > currentBid && submittedBidType !== BID_TYPES.BID_FLOOR) {
      openHigherBidModal();
    } else {
      handleSell();
    }
  };

  return (
    <div className={styles['lot-hover']}>
      <div className={styles['lot-hover']}>
        {showPassAndSellLotBtns ? (
          <>
            <button onClick={handlePassOpenLot} className={styles['btn']}>
              Pass / Open
            </button>
            <button onClick={handleSellOpen} className={styles['btn']}>
              Sell / Open
            </button>
          </>
        ) : (
          <button onClick={handlePassOpenLot} className={styles['btn']}>
            Open Lot
          </button>
        )}
      </div>
      {isHigherBidModalOpen && <HigherBidModal handleSell={handleSell} closeHigherBidModal={closeHigherBidModal} />}
    </div>
  );
}
