export const DEBOUNCE_TIME_MS = 500;

export enum SEARCH_GRID_CONFIG {
  HEIGHT = 320,
  ROW_HEIGHT = 105,
  WIDTH = 320,
  COLUMN_WIDTH = 99,
  COLUMNS = 3,
  OVER_SCAN_ROWS = 3,
}

export const LOTS_PER_ROW = 3;
