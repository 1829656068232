import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { HOT_KEYS_MAP } from 'components/constants';
import { mutateIncrement } from 'redux/middlewares/live-server/actions';
import { selectCurrentAuctionState } from 'redux/slices/broker/selectors';
import { AUCTION_STATES } from 'redux/slices/broker/types';
import { updateLotIncrementSet } from 'redux/slices/catalog';
import { selectIncrementSet, selectIncrementSetOnServer } from 'redux/slices/catalog/selectors';

import { AskAndIncrementNumericField } from '../ask-and-increment-numeric-field';
import { DEFAULT_INCREMENT } from './constants';

import styles from './increment.module.scss';

export function Increment() {
  const dispatch = useDispatch();
  const previousIncrement = useSelector(selectIncrementSetOnServer);
  const updatedIncrement = useSelector(selectIncrementSet);
  const auctionState = useSelector(selectCurrentAuctionState);

  const incrementAmountLength = updatedIncrement.toString().length;

  const handleUpdateIncrement = (incrementType = '') => {
    if (auctionState === AUCTION_STATES.IN_PROGRESS) {
      if (incrementType === DEFAULT_INCREMENT) {
        dispatch(mutateIncrement(0)); // Sending 0 sets the increment to default increment
      } else if (previousIncrement !== updatedIncrement) {
        dispatch(mutateIncrement(updatedIncrement));
      } else {
        dispatch(updateLotIncrementSet(previousIncrement));
      }
    }
  };

  const onIncrementChange = (changedIncrement: string) => {
    dispatch(updateLotIncrementSet(changedIncrement));
  };

  const editableFieldClasses = classNames(styles['editable-text'], {
    [styles['editable-text-md']]: incrementAmountLength > 5 && incrementAmountLength <= 8,
    [styles['editable-text-lg']]: incrementAmountLength > 8,
  });

  return (
    <div className={styles['increment']}>
      <span
        aria-hidden={true}
        onClick={() => handleUpdateIncrement(DEFAULT_INCREMENT)}
        className={styles['reset-increment']}
      >
        table
      </span>
      <span>Increment:</span>
      <span className={editableFieldClasses}>
        <AskAndIncrementNumericField
          value={updatedIncrement}
          onChange={onIncrementChange}
          hotkeysToEnableEditMode={HOT_KEYS_MAP.EDIT_INCREMENT_FIELD}
          onBlur={handleUpdateIncrement}
        />
      </span>
    </div>
  );
}
