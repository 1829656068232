import { useDispatch } from 'react-redux';

import { HTML_TAGS_REGEX } from 'components/right-panel/send-msgs-revert-bid/custom-messages/constants';
import { mutateMessage } from 'redux/middlewares/live-server/actions';

import { DraggableMessages } from './draggable-messages';
import { ICustomMessagePopoverProps } from './types';

import styles from './popover.module.scss';

export function CustomMessagePopover({
  setIsPopoverShown,
  customMessageInput,
  setCustomMessageInput,
  messages,
  setMessages,
}: ICustomMessagePopoverProps) {
  const dispatch = useDispatch();

  const handleSaveMessage = () => {
    const messageToSave = customMessageInput.replace(HTML_TAGS_REGEX, '');
    messageToSave && setMessages([...messages, messageToSave]);
  };

  const handleSendMessage = (index?: number) => {
    const messageToSend = typeof index === 'number' ? messages[index] : customMessageInput.replace(HTML_TAGS_REGEX, '');
    if (messageToSend) {
      dispatch(mutateMessage(messageToSend));
      setIsPopoverShown(false);
    }
  };

  return (
    <div className={`${styles['custom-message-popover']}`}>
      <div className={`${styles['popover-header']}`}>
        <input
          type='text'
          name='custom message'
          value={customMessageInput}
          onChange={e => setCustomMessageInput(e.target.value)}
        />
        <div className={`${styles['send-save-message']}`}>
          <button type={'button'} className={`${styles['button']}`} onClick={handleSaveMessage}>
            Save Message
          </button>
          <button type={'button'} className={`${styles['button']}`} onClick={() => handleSendMessage()}>
            SEND Message
          </button>
        </div>
      </div>
      <DraggableMessages messages={messages} setMessages={setMessages} handleSendMessageClick={handleSendMessage} />
    </div>
  );
}
