import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { selectCurrentLotNumber } from 'redux/slices/broker/selectors';
import { selectCatalogLots } from 'redux/slices/catalog/selectors';

import { MS_IN_A_SECOND, SALE_CLOSING_IN_SECONDS, SHOW_WARNING_AFTER_SECONDS } from './constants';
import { WarningModal } from './warning-modal';

export const LastLotTimer = () => {
  const currentLotNumber = useSelector(selectCurrentLotNumber);
  const catalogLots = useSelector(selectCatalogLots);
  const [isLastLotPopupClosed, setIsLastLotPopupClosed] = useState(false);
  const [hasLastLotWarningTimePassed, setHasLastLotWarningTimePassed] = useState(false);
  const [saleCloseSecondsLeft, setSaleCloseSecondsLeft] = useState(SALE_CLOSING_IN_SECONDS);
  const [saleEnded, setSaleEnded] = useState(false);
  const [lastLotTimeout, setLastLotTimeout] = useState<ReturnType<typeof setTimeout> | null>(null);
  const [saleCloseInterval, setSaleCloseInterval] = useState<ReturnType<typeof setInterval> | null>(null);

  const isOnLastLot = catalogLots.size > 1 && currentLotNumber === Array.from(catalogLots.keys())[catalogLots.size - 1];
  const isShowLastLotModal = isOnLastLot && hasLastLotWarningTimePassed && !isLastLotPopupClosed;

  useEffect(() => {
    return () => {
      lastLotTimeout && clearTimeout(lastLotTimeout);
      saleCloseInterval && clearInterval(saleCloseInterval);
    };
  }, []);

  useEffect(() => {
    if (saleCloseSecondsLeft <= 0) {
      saleCloseInterval && clearInterval(saleCloseInterval);
      setSaleCloseInterval(null);
      setSaleEnded(true);
    }
  }, [saleCloseSecondsLeft]);

  useEffect(() => {
    if (currentLotNumber && !saleEnded) {
      if (isOnLastLot && !lastLotTimeout && !saleCloseInterval) {
        setLastLotTimeout(
          setTimeout(() => {
            setHasLastLotWarningTimePassed(true);
            setLastLotTimeout(null);
            setIsLastLotPopupClosed(false);
            setSaleCloseInterval(
              setInterval(() => {
                setSaleCloseSecondsLeft(seconds => seconds - 1);
              }, MS_IN_A_SECOND),
            );
          }, SHOW_WARNING_AFTER_SECONDS * MS_IN_A_SECOND),
        );
      } else if (!isOnLastLot) {
        if (lastLotTimeout) {
          clearTimeout(lastLotTimeout);
          setLastLotTimeout(null);
        }
        if (saleCloseInterval) {
          clearInterval(saleCloseInterval);
          setSaleCloseInterval(null);
          setSaleCloseSecondsLeft(SALE_CLOSING_IN_SECONDS);
        }
      }
    }
  }, [currentLotNumber]);

  if (isShowLastLotModal) {
    return <WarningModal closeModal={() => setIsLastLotPopupClosed(true)} secondsLeft={saleCloseSecondsLeft} />;
  }
  return null;
};
