import { escape } from 'underscore';

import { BID_TYPES } from 'components/right-panel/types';

import { LIVE_SERVER_ACTIONS, ISellPayload } from './types';

/**
 * @description Returns an action to mutate the floor bid or internet bid
 * @param {string} bidType
 * * @param {number} bid
 * */
export const mutateBidAccept = (bidType: BID_TYPES, bid: number) => {
  return {
    type: LIVE_SERVER_ACTIONS.BID_ACCEPT,
    payload: {
      bidType,
      bid,
    },
  };
};

/**
 * @description Returns an action to mutate the incrementSet
 * @param incrementData increment payload
 */
export const mutateIncrement = (incrementData: number) => ({
  type: LIVE_SERVER_ACTIONS.INCREMENT,
  payload: incrementData,
});

/**
 * @description Returns an action to mutate Ask
 * @param askAmount ask payload
 */
export const mutateAsk = (askAmount: number) => ({
  type: LIVE_SERVER_ACTIONS.ASK,
  payload: askAmount,
});

/**
 * @description Returns an action to mutate nextUnsold
 * @param message nextUnsold payload
 */
export const mutateNextUnsold = (message: string) => ({
  type: LIVE_SERVER_ACTIONS.NEXT_UNSOLD,
  payload: message,
});

/**
 * @description Returns an action to mutate Message
 * @param message: string
 */
export const mutateMessage = (message: string) => ({
  type: LIVE_SERVER_ACTIONS.MESSAGE,
  payload: escape(message),
});

/**
 * @description Returns an action to mutate sell
 * @param payload sell payload
 */
export const mutateSell = (payload: ISellPayload) => {
  const { triggerNextActionOnSuccessfulSell = true } = payload;
  return {
    type: LIVE_SERVER_ACTIONS.SELL,
    payload: {
      nextAction: payload.nextAction,
      triggerNextActionOnSuccessfulSell,
    },
  };
};

/**
 * @description Returns an action to mutate Lot
 * @param lotNumber: string
 */
export const mutateLot = (lotNumber = '') => ({
  type: LIVE_SERVER_ACTIONS.LOT,
  payload: lotNumber,
});

/**
 * @description Returns an action to mutate Undo Sell
 * @param lotNumber: string
 */
export const mutateUndoSell = (lotNumber: string) => ({
  type: LIVE_SERVER_ACTIONS.UNDO_SELL,
  payload: lotNumber,
});

export const MUTATE_START_AUCTION = { type: LIVE_SERVER_ACTIONS.AUCTION_START };
export const MUTATE_UNDO_BID = { type: LIVE_SERVER_ACTIONS.UNDO_BID };
export const MUTATE_STOP = { type: LIVE_SERVER_ACTIONS.STOP };
export const MUTATE_CLOSE = { type: LIVE_SERVER_ACTIONS.CLOSE };
