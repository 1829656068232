export interface IHttpRequestOptions {
  headers?: Record<string, string>;
  queryParams?: Record<string, string>;
}

export enum REST_RESPONSE_STATUS_CODES {
  SUCCESS = 200,
}

export interface IStandardResponse {
  data: Record<string, unknown> | unknown;
  statusCode: number;
  statusMessage: string;
  success: boolean;
}
