/**
 * A helper to return formatted seconds into MM:SS format
 * @param {number} seconds seconds
 * @returns {string} MM:SS formatted time
 */
export const formatSeconds = (seconds: number) => {
  const date = new Date(0);
  date.setSeconds(seconds);
  return date.toISOString().substr(14, 5);
};
