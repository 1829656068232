import styles from './page-not-found.module.scss';

export function PageNotFound404() {
  return (
    <div className={styles['page-error-holder']}>
      <h1>
        <span>404</span>
        {", The page doesn't exist."}
      </h1>
    </div>
  );
}
