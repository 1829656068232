export enum BID_TYPES {
  BID_INTERNET = 'bidInternet',
  BID_FLOOR = 'bidFloor',
  BID_ORDER = 'bidOrder',
}
export enum BID_TYPE_TEXT {
  BID_INTERNET = 'Internet Bidder',
  BID_FLOOR = 'Floor',
}

export enum BID_BUTTON_FONT_SIZE {
  DEFAULT = 36,
  MEDIUM = 28,
  SMALL = 24,
}

export type TGetBidAcceptPayloadMsgParams = {
  lotNumber: string;
  currency: string;
  bid: number;
  paddle: string;
  bidType: string;
};

export type TGetUndoBidMsgParams = {
  bid: number;
  currency: string;
  lotNumber: string;
};
