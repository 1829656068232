import { captureException } from '@sentry/react';

import { formatError } from 'utils';

/**
 A hook to handle sentry error event logging in case of API/Broker errors
 */
export function useSentryApiErrorLogging(isConnectError: boolean, isCatalogError: boolean, isSocketError: boolean) {
  if (isConnectError) {
    const error = formatError('Connect call failed', 'API Error');
    captureException(error);
  } else if (isSocketError) {
    const error = formatError('Failed to connect with broker', 'Broker Error');
    captureException(error);
  }
  if (isCatalogError) {
    const error = formatError('Failed to fetch Catalog data', 'API Error');
    captureException(error);
  }
}
