import { ISellerConsoleModalProps } from './types';

import styles from './seller-console-modal.module.scss';

/**
 * Following are the possible values for these props
 * primaryBtnClass: green
 * secondaryBtnClass: link | gray-dark
 */
export function SellerConsoleModal({
  title,
  description,
  primaryBtnText,
  primaryBtnClass = '',
  primaryBtnClickHandler,
  secondaryBtnText,
  secondaryBtnClickHandler,
  secondaryBtnClass = '',
  containerClass = '',
}: ISellerConsoleModalProps) {
  return (
    <div className={`${styles['seller-console-modal']} ${containerClass}`}>
      <div className={styles['modal-dialog']}>
        <div className={styles['modal-content']}>
          <div className={styles['modal-body']}>
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
          <div className={`${styles['modal-footer']} ${styles['text-right']}`}>
            {secondaryBtnText && (
              <button onClick={secondaryBtnClickHandler} className={`${styles['btn']} ${styles[secondaryBtnClass]}`}>
                {secondaryBtnText}
              </button>
            )}
            {primaryBtnText && (
              <button onClick={primaryBtnClickHandler} className={`${styles['btn']} ${styles[primaryBtnClass]}`}>
                {primaryBtnText}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
