import { useDispatch, useSelector } from 'react-redux';

import { STATIC_MESSAGES } from 'components/constants';
import { mutateMessage } from 'redux/middlewares/live-server/actions';
import { brokerActions } from 'redux/slices/broker';
import { selectCurrentAuctionState } from 'redux/slices/broker/selectors';
import { AUCTION_STATES } from 'redux/slices/broker/types';

import styles from '../send-msgs-revert-bid.module.scss';

export function FairWarning() {
  const dispatch = useDispatch();
  const auctionState = useSelector(selectCurrentAuctionState);

  const handleSendFairWarning = () => {
    if (auctionState === AUCTION_STATES.IN_PROGRESS) {
      dispatch(mutateMessage(STATIC_MESSAGES.FAIR_WARNING));
    } else {
      dispatch(brokerActions.displayCustomMessage({ message: STATIC_MESSAGES.START_AUCTION_FIRST }));
    }
  };

  return (
    <button className={`${styles['btn']} ${styles['green']}`} onClick={handleSendFairWarning}>
      <span>Fair Warning</span>
    </button>
  );
}
