import { useEffect } from 'react';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { ENV } from 'configs';
import { SENTRY_DEV_ENV } from 'hooks/constants';

/**
 * A hook to initiate sentryIO in our app with required configuration
 */
export function useSentryIo(): void {
  useEffect(() => {
    if (!ENV.IS_LOCAL) {
      const sentryOptions: Record<string, unknown> = {
        dsn: ENV.SENTRY_DSN_KEY,
        integrations: [new BrowserTracing()],
        environment: ENV.SENTRY_ENVIRONMENT || SENTRY_DEV_ENV,
        tracesSampleRate: 1.0,
      };

      if (ENV.SENTRY_RELEASE_VERSION) {
        sentryOptions.release = ENV.SENTRY_RELEASE_VERSION;
      }

      Sentry.init({
        ...sentryOptions,
      });
    }
  }, []);
}
