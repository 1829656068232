import { RootState } from 'redux/store';
import { TLot } from 'types';

import { selectCurrentLotNumber } from '../broker/selectors';

/**
 * A function that selects lots from the catalog
 * @param {RootState} state of store
 * @returns {Map<string, TLot>} catalog lots
 */
export const selectCatalogLots = (state: RootState): Map<string, TLot> => state.catalog.lots;

/**
 * A function that return catalogRef
 * @param {RootState} state of store
 * @returns {string} catalogRef
 */
export const selectCatalogRef = (state: RootState): string => state.catalog.catalogRef;

/**
 * A function that selects the current lot in the store.
 * @param {RootState} state of store
 * @returns {TLot} lot
 */
export const selectCurrentLot = (state: RootState): TLot | undefined => {
  const lotNumber = selectCurrentLotNumber(state);
  return state.catalog.lots.get(lotNumber);
};

/**
 * A function that selects the currency code for the catalog
 * @param {RootState} state of store
 * @returns {string} currency code
 */
export const selectCurrencyCode = (state: RootState): string => state.catalog.currencyCode;

/**
 * A function that checks if the lots are loaded
 * @param {RootState} state of store
 * @returns {boolean} lots loading state
 */
export const selectIsLotsFetched = (state: RootState): boolean => state.catalog.isLotsFetched;

/**
 * A function that selects the total number of sold lots in the store
 * @param {RootState} state of store
 * @returns {number} total number of sold lots
 */
export const selectTotalSoldLots = (state: RootState): number => state.catalog.totalSoldLots;

/**
 * A function that selects the increment set
 * NOTE: It syncs with the user input in increment field on the console.
 * @param {RootState} state of store
 * @returns {number} increment set
 */
export const selectIncrementSet = (state: RootState): number => state.catalog.incrementSet;

/**
 * A function that selects the incrementSet that is currenly on the server
 * @param {RootState} state of store
 * @returns {number} backup increment set
 */
export const selectIncrementSetOnServer = (state: RootState): number => state.catalog.incrementSetOnServer;

/**
 * A function that returns true if there was an error while fetching catalog and lots data
 * @param state
 */
export const selectIsCatalogConnectionError = (state: RootState): boolean => state.catalog.errors.callFailure;
