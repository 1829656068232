import React, { useEffect } from 'react';

import ReactDOM from 'react-dom';

import { ENV } from 'configs';

import { DELAY_RENDERED_COMPONENT_CHECKING_MS } from '../constants';

/**
 * A hook to audit our application for accessibility and logs any accessibility issues to the Chrome DevTools console.
 * It uses the axe-core (i.e wrapper of open-source axe testing library) to flag any issues and their severity.
 */
export function useAxeCore(): void {
  useEffect(() => {
    if (ENV.IS_LOCAL) {
      // We are requiring this package here because we have added this package as a dev-dependency and
      // if we import it at top we will get an error in non-dev environments.
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const { default: axe } = require('@axe-core/react');
      axe(React, ReactDOM, DELAY_RENDERED_COMPONENT_CHECKING_MS);
    }
  }, []);
}
