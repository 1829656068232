import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'redux/store';
import { catalogServiceInstance } from 'services/catalog';

import { selectCatalogRef } from './selectors';

export const fetchCatalog = createAsyncThunk('catalog/fetch', async (_, thunkAPI) => {
  const catalogRef = selectCatalogRef(thunkAPI.getState() as RootState);
  const response = await catalogServiceInstance.fetchCatalogAndLotInfo(catalogRef);

  if (!response.success) {
    return thunkAPI.rejectWithValue({
      statusCode: response.statusCode,
      statusMessage: response.statusMessage,
    });
  }
  return response.data;
});
