import { CustomMessages } from './custom-messages';
import { FairWarning } from './fair-warning';
import { RevertBid } from './revert-bid';

import styles from './send-msgs-revert-bid.module.scss';

export function SendMessagesRevertBid() {
  return (
    <div className={styles['send-messages-revert-bid']}>
      <RevertBid />
      <CustomMessages />
      <FairWarning />
    </div>
  );
}
