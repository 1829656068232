import { Droppable, DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd';

import { reorderMessages, removeMessageAtIndex } from './helpers';
import { IDraggableMessagesProps } from './types';

import styles from './popover.module.scss';

export function DraggableMessages({ messages, setMessages, handleSendMessageClick }: IDraggableMessagesProps) {
  const handleRemoveCustomMessage = (index: number) => setMessages(removeMessageAtIndex(messages, index));

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const reorderedMessages = reorderMessages(messages, result.source.index, result.destination.index);
    setMessages(reorderedMessages);
  };

  const messagesJSX = messages.map((message, index) => (
    <Draggable
      draggableId={`${index}-${message}`}
      key={`${index}-${message}`}
      index={index}
      disableInteractiveElementBlocking
    >
      {provided => (
        <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <button className={`${styles['messages']}`} onClick={() => handleSendMessageClick(index)}>
            <span>{message}</span>
          </button>
          <button className={`${styles['close-btn']}`} onClick={() => handleRemoveCustomMessage(index)}>
            X
          </button>
        </li>
      )}
    </Draggable>
  ));

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId='saved-messages'>
        {provided => (
          <ul className={`${styles['saved-messages']}`} ref={provided.innerRef} {...provided.droppableProps}>
            {messagesJSX}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
}
