import { RootState } from 'redux/store';

import { IAuctionInfoData } from './types';

/**
 * A function that selects the auction info in the store.
 * @param {RootState} state of store
 * @returns {IAuctionInfoData} auction info
 */
export const selectAuctionInfo = (state: RootState): IAuctionInfoData | undefined => state.auctionInfo.data;

/**
 * A function that selects the catalogId.
 * @param {RootState} state of store
 * @returns {string} catalogId
 */
export const selectCatalogId = (state: RootState): string => state.auctionInfo.data?.catalogId || '';

/**
 * A function that selects the loading state of the auction info in store.
 * @param {RootState} state of store
 * @returns {boolean} loading state
 */
export const selectIsConsoleRegisteredUsingConnect = (state: RootState): boolean =>
  !state.auctionInfo.isLoading && !!state.auctionInfo.data;

/**
 * A function that selects the sellerId
 * @param {RootState} state of store
 * @returns {string} sellerId
 */
export const selectSellerId = (state: RootState): string => state.auctionInfo.sellerId;

/**
 * A selector that returns true if a new seller console is registered against the catalogRef.
 * @param {RootState} state of store
 * @returns {boolean}
 */
export const selectIsSellerIdMismatched = (state: RootState): boolean => state.auctionInfo.errors.isSellerIdMismatched;

/**
 * A selector that returns true if a user is authorized to open a sale.
 * @param {RootState} state of store
 * @returns {boolean}
 */
export const selectIsUserAuthorized = (state: RootState): boolean => state.auctionInfo.errors.isUserAuthorized;

/**
 * A selector that returns true if connect call fails.
 * @param {RootState} state of store
 * @returns {boolean}
 */
export const selectIsConnectConnectionError = (state: RootState): boolean => state.auctionInfo.errors.isCallFailure;
