import axios from 'axios';
import { stringify } from 'query-string';

import { prepareGenericResponseForRESTClient } from './helpers';
import { IStandardResponse, IHttpRequestOptions } from './types';

/**
 * Generic client to interact with RESTFul endpoints
 */
export class RESTFulService {
  /**
   * A method that returns the API header information
   * @param options other metadata associated with the API request
   * @returns returns the API header information
   */
  async getHeaders(options?: IHttpRequestOptions): Promise<Record<string, string>> {
    let headers: Record<string, string> = {
      'Content-Type': 'application/json',
    };

    if (options?.headers) {
      // customise headers
      const { headers: customHeaders } = options;
      headers = { ...headers, ...customHeaders };
    }

    return headers;
  }

  /**
   * Sends a get request to the provided url using axios.get
   * @param url rest endpoint url
   * @param queryParams request payload
   * @param options other metadata associated with the API request
   * @returns returns the API response from backend
   */
  async get(
    url: string,
    queryParams: Record<string, string> | null = null,
    options?: IHttpRequestOptions,
  ): Promise<IStandardResponse> {
    const headers: Record<string, string> = await this.getHeaders(options);
    try {
      const response = await axios.get(url, {
        params: queryParams,
        paramsSerializer: params => stringify(params),
        headers,
      });
      return prepareGenericResponseForRESTClient(response);
    } catch (error) {
      return prepareGenericResponseForRESTClient(error);
    }
  }

  /**
   * Sends a POST request to provided url using axios.post
   * @param url rest endpoint url
   * @param data data request payload
   * @param options other metadata associated with the API request
   * @returns returns the API response from backend
   */
  async post(url: string, data: unknown, options?: IHttpRequestOptions): Promise<IStandardResponse> {
    const headers: Record<string, string> = await this.getHeaders(options);

    try {
      const response = await axios.post(url, data, { headers });
      return prepareGenericResponseForRESTClient(response);
    } catch (error) {
      return prepareGenericResponseForRESTClient(error);
    }
  }
}
