import { PayloadAction, Middleware } from '@reduxjs/toolkit';

import { STATIC_MESSAGES, HTTP_STATUS_CODES } from 'components/constants';
import { getAuctionReportURL, getSoldLotMessage } from 'components/helpers';
import { setSellerIdMismatched } from 'redux/slices/auction-info';
import { selectSellerId } from 'redux/slices/auction-info/selectors';
import { brokerActions } from 'redux/slices/broker';
import {
  selectCurrentLotNumber,
  selectSubmittedBidPaddle,
  selectApprovalId,
  selectCurrentBid,
  selectIsSellCallInProgress,
} from 'redux/slices/broker/selectors';
import { updateLotIncrementSetAndBackup, updateLotAcceptedBidder } from 'redux/slices/catalog';
import { selectCatalogRef, selectCurrencyCode } from 'redux/slices/catalog/selectors';
import { RootState } from 'redux/store';
import { LiveServerService } from 'services/live-server';
import { SELLER_CONSOLE_USER } from 'services/live-server/constants';
import { IStandardResponse } from 'services/rest-client';

import { mutateAsk } from './actions';
import { LOT_ALREADY_SOLD_API_ERROR_MSG } from './constants';
import { getBidAcceptPayloadMsg, getUndoBidMsg } from './helpers';
import { LIVE_SERVER_ACTIONS, ILiveServerResponse, ISellPayload, IBidAcceptPayload } from './types';

/**
 * A middleware to manage live server endpoint responses
 */
export const liveServerEndpointsMiddleware: Middleware = store => {
  let isBidAcceptInProgress = false;
  const { dispatch, getState } = store;
  const rootStateSnapshot = getState();
  const catalogRef = selectCatalogRef(rootStateSnapshot);
  const sellerId = selectSellerId(rootStateSnapshot);
  const isSellCallInProgress = selectIsSellCallInProgress(rootStateSnapshot);
  const liveServerService = new LiveServerService(catalogRef, sellerId, SELLER_CONSOLE_USER);

  /**
   * handleIncrementSet: update current lot with latest incrementSet value
   * @param responseData response from live server endpoint
   */
  const handleIncrementSet = (responseData: ILiveServerResponse) => {
    const { incrementSet } = responseData;
    if (incrementSet) {
      dispatch(updateLotIncrementSetAndBackup({ incrementSet }));
    }
  };

  /**
   * handleAcceptedBidder: update current lot with new accepted bidder
   * @param responseData response from live server endpoint
   * @param lotNumber
   */
  const handleAcceptedBidder = (responseData: ILiveServerResponse, lotNumber: string) => {
    const { acceptedBidder } = responseData;
    if (lotNumber) {
      dispatch(updateLotAcceptedBidder({ acceptedBidder, lotNumber }));
    }
  };

  /**
   * handleErrors: Take appropriate action or Dispatch an action based on the error
   * @param response
   */
  const handleErrors = (response: IStandardResponse) => {
    if (response.statusCode === HTTP_STATUS_CODES.UNAUTHORIZED) {
      dispatch(setSellerIdMismatched());
    }

    if (response.statusCode === HTTP_STATUS_CODES.SERVER_SIDE_ERROR) {
      dispatch(brokerActions.displayCustomMessage({ message: STATIC_MESSAGES.API_ERROR_500 }));
    }

    if (
      +response.statusCode === HTTP_STATUS_CODES.ALREADY_SOLD &&
      response.statusMessage === LOT_ALREADY_SOLD_API_ERROR_MSG
    ) {
      dispatch(brokerActions.displayCustomMessage({ message: STATIC_MESSAGES.LOT_ALREADY_SOLD_ERROR, isAlert: true }));
    }
  };

  return next => async (action: PayloadAction) => {
    const {
      BID_ACCEPT,
      MESSAGE,
      AUCTION_START,
      NEXT_UNSOLD,
      UNDO_BID,
      LOT,
      INCREMENT,
      ASK,
      STOP,
      SELL,
      UNDO_SELL,
      CLOSE,
    } = LIVE_SERVER_ACTIONS;
    const rootState: RootState = getState();

    const currentLotNumber = selectCurrentLotNumber(rootState);

    switch (action.type) {
      case AUCTION_START: {
        const message = `Lot ${currentLotNumber} open for bidding</li><li>Auction Started!`;
        const response = await liveServerService.startAuction({ message });
        if (!response.success) {
          handleErrors(response);
          break;
        }
        const start = response.data as ILiveServerResponse;
        handleIncrementSet(start);
        handleAcceptedBidder(start, currentLotNumber);
        break;
      }
      case MESSAGE: {
        const message = action.payload as unknown as string;
        const response = await liveServerService.message({ message });
        if (!response.success) handleErrors(response);
        break;
      }
      case BID_ACCEPT: {
        if (isBidAcceptInProgress) break;

        isBidAcceptInProgress = true;
        const payload = action.payload as unknown as IBidAcceptPayload;
        const { bidType, bid } = payload;
        const paddle = selectSubmittedBidPaddle(rootState);
        const currency = selectCurrencyCode(rootState);
        const approvalId = selectApprovalId(rootState);
        const response = await liveServerService.bidAccept({
          lotId: currentLotNumber,
          bid,
          bidType,
          approvalId,
          message: getBidAcceptPayloadMsg({
            lotNumber: currentLotNumber,
            currency,
            bid,
            paddle,
            bidType,
          }),
        });
        isBidAcceptInProgress = false;

        if (!response.success) {
          handleErrors(response);
          break;
        }
        const bidAccept = response.data as ILiveServerResponse;
        handleIncrementSet(bidAccept);
        handleAcceptedBidder(bidAccept, currentLotNumber);
        break;
      }
      case NEXT_UNSOLD: {
        const message = action.payload as unknown as string;
        const response = await liveServerService.nextUnsold({ message });
        if (!response.success) {
          handleErrors(response);
          break;
        }
        const nextUnsold = response.data as ILiveServerResponse;
        handleIncrementSet(nextUnsold);
        if (nextUnsold.acceptedBidder) handleAcceptedBidder(nextUnsold, currentLotNumber);
        break;
      }
      case UNDO_BID: {
        const bid = selectCurrentBid(rootState);
        const currency = selectCurrencyCode(rootState);
        const response = await liveServerService.undoBid({
          lotId: currentLotNumber,
          message: getUndoBidMsg({
            bid,
            currency,
            lotNumber: currentLotNumber,
          }),
        });
        if (!response.success) {
          handleErrors(response);
          break;
        }
        const undoBid = response.data as ILiveServerResponse;
        handleIncrementSet(undoBid);
        handleAcceptedBidder(undoBid, currentLotNumber);
        break;
      }
      case LOT: {
        const lotNumber = (action.payload as unknown as string) || currentLotNumber;
        const response = await liveServerService.lot({
          lotId: lotNumber,
        });
        if (!response.success) {
          handleErrors(response);
          break;
        }
        const lot = response.data as ILiveServerResponse;
        handleIncrementSet(lot);
        handleAcceptedBidder(lot, lotNumber);
        break;
      }
      case INCREMENT: {
        const incrementToSet = action.payload as unknown as number;
        const currentBid = selectCurrentBid(rootState);
        const response = await liveServerService.increment({
          lotId: currentLotNumber,
          increment: incrementToSet,
        });
        if (!response.success) {
          handleErrors(response);
          break;
        }
        const increment = response.data as ILiveServerResponse;
        if (increment.incrementSet && currentBid) {
          dispatch(mutateAsk(+currentBid + Number(increment.incrementSet)));
        }

        handleIncrementSet(increment);
        break;
      }
      case ASK: {
        const askAmount = action.payload as unknown as number;
        const response = await liveServerService.ask({
          lotId: currentLotNumber,
          ask: askAmount,
        });
        if (!response.success) {
          handleErrors(response);

          if (response.statusCode === HTTP_STATUS_CODES.SERVER_SIDE_ERROR) {
            // reset the ask
            dispatch(brokerActions.updateAskPrice(rootState.broker.currentLotData?.askPriceOnServer));
          }
          break;
        }
        const ask = response.data as ILiveServerResponse;
        handleIncrementSet(ask);
        break;
      }
      case STOP: {
        const response = await liveServerService.stop({
          message: STATIC_MESSAGES.AUCTION_PAUSED,
        });
        if (!response.success) {
          handleErrors(response);
          break;
        }
        break;
      }
      case SELL: {
        if (isSellCallInProgress) break;

        dispatch(brokerActions.setIsSellCallInProgress(true));
        const payloadMessage = getSoldLotMessage(currentLotNumber);
        const payload = action.payload as unknown as ISellPayload;
        const { nextAction, triggerNextActionOnSuccessfulSell } = payload;
        const response = await liveServerService.sell({
          lotId: currentLotNumber,
          message: payloadMessage,
        });
        if (!response.success) {
          dispatch(brokerActions.setIsSellCallInProgress(false));
          handleErrors(response);
          if (nextAction && !triggerNextActionOnSuccessfulSell) dispatch(nextAction);
          break;
        }

        if (nextAction) await dispatch(nextAction);

        dispatch(brokerActions.setIsSellCallInProgress(false));
        const { winningBidder } = response.data as ILiveServerResponse;
        handleAcceptedBidder({ acceptedBidder: winningBidder }, currentLotNumber);
        break;
      }
      case UNDO_SELL: {
        const lotNumber = action.payload as unknown as string;
        const response = await liveServerService.undoSell({
          lotId: lotNumber,
        });
        if (!response.success) {
          handleErrors(response);
          break;
        }
        const undoSell = response.data as ILiveServerResponse;
        handleAcceptedBidder(undoSell, lotNumber);
        break;
      }
      case CLOSE: {
        const response = await liveServerService.close({
          message: STATIC_MESSAGES.AUCTION_PAUSED,
        });
        if (!response.success) {
          handleErrors(response);
          break;
        }
        alert(STATIC_MESSAGES.AUCTION_ENDED);
        window.location.assign(getAuctionReportURL(selectCatalogRef(rootState)));
        break;
      }
      default:
        return next(action);
    }

    return null;
  };
};
