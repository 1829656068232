import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { HTTP_STATUS_CODES } from 'components/constants';

import { getSellerId } from './helpers';
import { fetchAuctionInfo } from './thunks';
import { IAuctionInfo, IAuctionInfoState } from './types';

const INITIAL_STATE: IAuctionInfoState = {
  data: undefined,
  sellerId: getSellerId(),
  isLoading: true,
  errors: {
    isCallFailure: false,
    isSellerIdMismatched: false,
    isUserAuthorized: true,
  },
};

const auctionInfoSlice = createSlice({
  name: 'auction-info',
  initialState: INITIAL_STATE,
  reducers: {
    setSellerIdMismatched: state => {
      state.errors.isSellerIdMismatched = true;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchAuctionInfo.fulfilled, (state, action: PayloadAction<IAuctionInfo, string>) => {
      const {
        houseName,
        eventTitle,
        eventDate,
        eventTime,
        state: stateAddress,
        country,
        catalogId,
        houseId,
        userId,
      } = action.payload || {};
      state.data = {
        houseName,
        eventTitle,
        eventDateTime: `${eventDate} ${eventTime}`,
        address: `${stateAddress}, ${country}`,
        catalogId,
        houseId,
        userId,
      };

      state.isLoading = false;
    });
    builder.addCase(fetchAuctionInfo.rejected, (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.errors.isCallFailure = true;
      const { statusCode } = action.payload || {};

      if (statusCode === HTTP_STATUS_CODES.UNAUTHORIZED) {
        state.errors.isUserAuthorized = false;
      }
    });
  },
});

export const { setSellerIdMismatched } = auctionInfoSlice.actions;

export { fetchAuctionInfo } from './thunks';
export const auctionInfoReducer = auctionInfoSlice.reducer;
