import { useDispatch, useSelector } from 'react-redux';

import { SellerConsoleModal } from 'components/common/seller-console-modal';
import { BID_TYPES } from 'components/right-panel/types';
import { useSellNext } from 'hooks/use-sell-next';
import { mutateBidAccept } from 'redux/middlewares/live-server/actions';
import { selectCurrentLotNumber, selectSubmittedBid, selectSubmittedBidType } from 'redux/slices/broker/selectors';
import { selectCurrencyCode } from 'redux/slices/catalog/selectors';

import { IHigherBidModalProps } from './types';

export function HigherBidModal({ closeHigherBidModal, handleSell }: IHigherBidModalProps) {
  const dispatch = useDispatch();
  const submittedBid = useSelector(selectSubmittedBid);
  const currencyCode = useSelector(selectCurrencyCode);
  const currentLotNumber = useSelector(selectCurrentLotNumber);
  const submittedBidType = useSelector(selectSubmittedBidType);
  const sell = handleSell || useSellNext();

  const handleBidAccept = () => {
    dispatch(mutateBidAccept(submittedBidType as BID_TYPES, submittedBid));
    closeHigherBidModal();
  };

  const handleSellNext = () => {
    sell();
    closeHigherBidModal();
  };

  return (
    <SellerConsoleModal
      title='Higher Bid Waiting'
      description={
        <>
          A higher internet bid of{' '}
          <b>
            {submittedBid} {currencyCode}
          </b>{' '}
          was just received for Lot #{currentLotNumber}. Do you want to accept it?
        </>
      }
      primaryBtnText='Yes, accept higher bid'
      secondaryBtnText={`No, sell Lot #${currentLotNumber}`}
      primaryBtnClass='green'
      primaryBtnClickHandler={handleBidAccept}
      secondaryBtnClickHandler={handleSellNext}
      secondaryBtnClass='link'
    />
  );
}
