import { Action, Middleware } from '@reduxjs/toolkit';
import { captureException } from '@sentry/react';
import { ActivationState, Client } from '@stomp/stompjs';

import { USER_CONNECTION_STATUS } from 'components/constants';
import { ENV } from 'configs';
import { brokerActions } from 'redux/slices/broker';
import { selectConnectionStatus } from 'redux/slices/broker/selectors';
import { STOMP_ACTION_TYPES, TBrokerMessage } from 'redux/slices/broker/types';
import { updateLotAcceptedPaddle, updateSoldUnsoldCatalogLot } from 'redux/slices/catalog';
import { RootState } from 'redux/store';
import { formatError } from 'utils';

import {
  STOMP_CONFIG,
  WEBSOCKET_ENDPOINTS,
  INCREASE_RECONNECT_DELAY_AFTER_ATTEMPTS,
  RECONNECTING_TOAST_DELAY_MS,
  RECONNECT_DELAY_IF_SALE_NOT_FOUND,
  MS_IN_A_SECOND,
  SOCKET_CONNECTION_ERROR_DISPLAY_DELAY,
  MAX_BROKER_SALE_LOAD_TIME,
  SOCKET_CONN_ERROR_SENTRY_MESSAGE,
  USER_ID_QUERY_PARAM,
} from './constants';
import {
  getLotMessages,
  getParsedBrokerMessage,
  getCurrentLotData,
  getPaddleData,
  getSocketDestination,
} from './helpers';
import { BID_EVENTS, WEBSOCKET_CLOSE_STATUSES } from './types';

/**
 * A singleton function that manages the creation, activation and termination of websocket client.
 * Also, handles the different events and dispatch the actions for the reducers that are written
 * in the broker slice.
 */
export const socketMiddleware: Middleware = store => {
  const { dispatch, getState } = store;
  let currentConnectionTry = 0;
  let connectionErrorTimerID: NodeJS.Timeout;

  let isWaitingForSaleToLoadOnBroker = true;
  setTimeout(() => {
    isWaitingForSaleToLoadOnBroker = false;
  }, MAX_BROKER_SALE_LOAD_TIME);

  const client: Client = new Client({
    brokerURL: WEBSOCKET_ENDPOINTS.BROKER,
    reconnectDelay: STOMP_CONFIG.INIT_RECONNECTION_DELAY_MS,
    heartbeatIncoming: STOMP_CONFIG.HEARTBEAT_INCOMING_MS,
    heartbeatOutgoing: STOMP_CONFIG.HEARTBEAT_OUTGOING_MS,
    discardWebsocketOnCommFailure: STOMP_CONFIG.DISCARD_WEBSOCKET,
  });

  /**
   * A function that terminates the socket client connection.
   */
  const terminateSocket = () => {
    /**
     * If we deactivate the socket and try to reactivate it too soon, as is the case with react fast reload
     for development it will throw an error that the client is still disconnecting, So we forceDisconnect
     the client before deactivating
     */
    if (client.state === ActivationState.ACTIVE) {
      client.forceDisconnect();
      client.deactivate();
    }
  };

  /**
   * A function that handles different events and then dispatch the actions
   * for the reducers that are written in the broker slice.
   * @param parsedData: parsed TBrokerMessage object
   */
  const dispatchMessages = (parsedData: TBrokerMessage) => {
    const { id, event } = parsedData;
    const { auctionState } = parsedData.data;

    if (event !== BID_EVENTS.EVENT) {
      dispatch(brokerActions.updateCurrentLot(getCurrentLotData(parsedData.data)));
      dispatch(brokerActions.updateSignInCount(parsedData.data?.items[0]?.signInCount));
      dispatch(brokerActions.updateAuctionState(auctionState));
      dispatch(updateLotAcceptedPaddle(getPaddleData(parsedData.data)));
    }

    dispatch(brokerActions.addLotMessage(getLotMessages(parsedData)));

    switch (event) {
      case BID_EVENTS.EVENT:
        dispatch(brokerActions.setIsConnected());
        dispatch(
          updateSoldUnsoldCatalogLot({
            items: parsedData.data.items,
            eventType: event,
          }),
        );
        break;
      case BID_EVENTS.LOT:
      case BID_EVENTS.SELL:
      case BID_EVENTS.UNDOSELL:
        dispatch(
          updateSoldUnsoldCatalogLot({
            items: parsedData.data.items,
          }),
        );
        break;
      case BID_EVENTS.ASK:
      case BID_EVENTS.START:
      case BID_EVENTS.STOP:
      case BID_EVENTS.UNDO_BID:
      case BID_EVENTS.NEXT_UNSOLD:
      case BID_EVENTS.BID_SUBMIT:
      case BID_EVENTS.BID_INCREMENT:
      case BID_EVENTS.MESSAGE:
        break;
      case BID_EVENTS.CLOSE:
        terminateSocket();
        break;
      default:
        return;
    }

    id && dispatch(brokerActions.updateLastEventId(id));
  };

  /**
   * A function that connects/subscribes to the auction channel to receive all the events
   * from the broker stream and then after parsing, passing them to the message handler.
   */
  const onConnect = () => {
    clearTimeout(connectionErrorTimerID);
    currentConnectionTry = 0;
    client.reconnectDelay = STOMP_CONFIG.INIT_RECONNECTION_DELAY_MS;

    const rootState: RootState = getState();
    const lastEventId = rootState.broker.lastEventId;
    const catalogRef = rootState.catalog.catalogRef;
    const userID = new URLSearchParams(window.location.search).get(USER_ID_QUERY_PARAM);
    const userConnectionStatus = selectConnectionStatus(rootState);
    const { CONNECTED } = USER_CONNECTION_STATUS;

    if (navigator.onLine && userConnectionStatus !== CONNECTED) {
      dispatch(brokerActions.setConnectionStatus(CONNECTED));
    }

    client.subscribe(
      getSocketDestination(catalogRef, userID),
      msg => {
        if (msg.body) {
          const jsonBody = JSON.parse(msg.body);
          if (jsonBody?.text) {
            const parsedData = getParsedBrokerMessage(jsonBody.text);
            dispatchMessages(parsedData);
          }
        }
      },
      { 'Last-Event-ID': lastEventId },
    );
  };

  /**
   * A function that is called when the websocket connection is closed.
   * @param event: A CloseEvent sent to clients using WebSockets when the connection is closed
   */
  const onWebSocketClose = (event: CloseEvent) => {
    if (event.code === WEBSOCKET_CLOSE_STATUSES.UNACCEPTABLE) {
      client.reconnectDelay = RECONNECT_DELAY_IF_SALE_NOT_FOUND;

      if (!isWaitingForSaleToLoadOnBroker) {
        dispatch(brokerActions.setConnectionError());
        terminateSocket();
      }
    }

    ENV.IS_LOCAL && console.log(`Exponential back off - next connection attempt in ${client.reconnectDelay}ms`);
  };

  /**
   * A function that will be invoked every time before connecting to the WebSocket.
   */
  const beforeConnect = () => {
    currentConnectionTry++;
    if (currentConnectionTry % INCREASE_RECONNECT_DELAY_AFTER_ATTEMPTS === 0) {
      client.reconnectDelay =
        STOMP_CONFIG.INIT_RECONNECTION_DELAY_MS +
        (currentConnectionTry / INCREASE_RECONNECT_DELAY_AFTER_ATTEMPTS) * MS_IN_A_SECOND;
    }
    ENV.IS_LOCAL &&
      console.log(`Connection attempt:${currentConnectionTry}, Reconnect Delay:${client.reconnectDelay}ms`);

    const rootState: RootState = getState();
    const userConnectionStatus = selectConnectionStatus(rootState);
    const { OFFLINE, RECONNECTING } = USER_CONNECTION_STATUS;
    if (!navigator.onLine && userConnectionStatus !== OFFLINE && userConnectionStatus !== RECONNECTING) {
      dispatch(brokerActions.setConnectionStatus(OFFLINE));
      if (userConnectionStatus !== RECONNECTING) {
        setTimeout(() => {
          if (!navigator.onLine && userConnectionStatus !== RECONNECTING) {
            dispatch(brokerActions.setConnectionStatus(RECONNECTING));
          }
        }, RECONNECTING_TOAST_DELAY_MS);
      }
    }
  };

  client.onConnect = onConnect;
  client.onWebSocketClose = onWebSocketClose;
  client.beforeConnect = beforeConnect;

  return next => (action: Action) => {
    const { INITIALIZE, TERMINATE } = STOMP_ACTION_TYPES;
    switch (action.type) {
      case INITIALIZE:
        client.activate();
        connectionErrorTimerID = setTimeout(() => {
          // This callback is executed only if the socket connection is not successful after
          // SOCKET_CONNECTION_ERROR_DISPLAY_DELAY time. Otherwise, the timeout is cancelled.
          dispatch(brokerActions.setConnectionError());
          const error = formatError(SOCKET_CONN_ERROR_SENTRY_MESSAGE, 'Broker Error');
          captureException(error);
        }, SOCKET_CONNECTION_ERROR_DISPLAY_DELAY);
        break;
      case TERMINATE:
        terminateSocket();
        break;
      default:
        return next(action);
    }
    return null;
  };
};
