import { captureException } from '@sentry/react';

import { IStandardResponse } from 'services/rest-client';
import { REST_RESPONSE_STATUS_CODES } from 'services/rest-client/types';

/**
 * @param formattedResponse {IStandardResponse} - for checking the response status for capturing exception
 * @returns formattedResponse
 */
export const capturingExceptionInResponse = (formattedResponse: IStandardResponse): IStandardResponse => {
  if (!formattedResponse.success) {
    captureException(formattedResponse.data);
  }
  return formattedResponse;
};

/**
 * Prepares a generic response to be sent to Action Layer
 * Purpose is to handle all REST API response inconsistencies at this level
 * */
export const prepareGenericResponseForRESTClient = (response: any): IStandardResponse => {
  let formattedResponse: IStandardResponse | null = null;

  if (response.status === REST_RESPONSE_STATUS_CODES.SUCCESS && response.data?.cmd !== 'error') {
    formattedResponse = {
      data: response.data,
      statusCode: 200,
      statusMessage: 'Request processed successfully',
      success: true,
    };
  } else if (response.data?.cmd === 'error') {
    formattedResponse = {
      data: response.data,
      statusCode: response.data.errorCode,
      statusMessage: response.data.errorMessage,
      success: false,
    };
  } else {
    formattedResponse = {
      data: response.response.data || response,
      statusCode: response.response.status,
      statusMessage: response.response.data,
      success: false,
    };
  }

  return capturingExceptionInResponse(formattedResponse);
};
