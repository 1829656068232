import { RESTFulService, IStandardResponse } from 'services/rest-client';

import { mergeCatalogAndLotData, getCatalogURL, getLotURL } from './helpers';
import { ICatalogResponse, ILotsResponse } from './types';

/**
 * Catalog service is inherited from RESTFulService and is responsible for fetching catalog data.
 */
class CatalogService extends RESTFulService {
  /**
   * Returns catalog and lots data fetched from the REST URL.
   * @param catalogId
   */
  fetchCatalogAndLotInfo = async (catalogId: string): Promise<IStandardResponse> => {
    const catalogURL = getCatalogURL(catalogId);
    const catalogResponse = await this.get(catalogURL);

    if (!catalogResponse.success) {
      return catalogResponse;
    }

    const catalogResponseData = catalogResponse.data as ICatalogResponse;
    const [firstLotId] = catalogResponseData.lotIDList;
    const numLots = catalogResponseData.lotIDList.length;

    const lotUrl = getLotURL(catalogId, firstLotId, numLots);
    const lotResponse = await this.get(lotUrl);

    if (!lotResponse.success) {
      return lotResponse;
    }

    return {
      data: mergeCatalogAndLotData(catalogResponseData, lotResponse.data as ILotsResponse),
      success: true,
      statusCode: 200,
      statusMessage: 'Request processed successfully',
    };
  };
}

export const catalogServiceInstance = new CatalogService();
