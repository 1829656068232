import { useState, useCallback, useEffect } from 'react';

import { debounce } from 'lodash';
import { useSelector } from 'react-redux';

import { selectCatalogLots } from 'redux/slices/catalog/selectors';
import { TLot } from 'types';

import { DEBOUNCE_TIME_MS } from './constants';
import { searchLots } from './helpers';
import { SearchLotsResult } from './search-lots-result';
import { ISearchLotPopover } from './types';

import styles from './search-lot-popover.module.scss';

export function SearchLotPopover({ handleSearchPopoverToggle }: ISearchLotPopover) {
  const lots = useSelector(selectCatalogLots);

  const [searchQuery, setSearchQuery] = useState('');
  const [searchedLots, setSearchResult] = useState<TLot[]>([]);

  const handleSearch = (searchString: string) => {
    const searchResultLots = searchLots(lots, searchString);
    setSearchResult(searchResultLots);
  };

  const debounceHandleSearch = useCallback(debounce(handleSearch, DEBOUNCE_TIME_MS), []);

  useEffect(() => {
    searchQuery && debounceHandleSearch(searchQuery);
  }, [searchQuery]);

  return (
    <div className={styles['search-lot-popover']}>
      {searchQuery && searchedLots.length ? (
        <SearchLotsResult lots={searchedLots} />
      ) : (
        <div className={styles['search-result-text']}>Search by lot title or by lot number</div>
      )}
      <button className={styles['close-popover-btn']} onClick={handleSearchPopoverToggle} />
      <div className={styles['search-bar']}>
        <button className={styles['clear-btn']} onClick={() => setSearchQuery('')} />
        <input
          type='text'
          className={styles['search-input']}
          placeholder='search'
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
        />
      </div>
    </div>
  );
}
