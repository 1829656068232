export enum LIVE_SERVER_COMMANDS {
  CONNECT = 'connect',
  LOT = 'lot',
  BID_ACCEPT = 'bidAccept',
  UNDO_BID = 'undoBid',
  MESSAGE = 'message',
  NEXT_UNSOLD = 'nextUnsold',
  START = 'start',
  STOP = 'stop',
  INCREMENT = 'increment',
  ASK = 'ask',
  SELL = 'sell',
  UNDO_SELL = 'undoSell',
  CLOSE = 'close',
}

export const SELLER_CONSOLE_USER = 'seller';
