import React from 'react';

import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import App from 'app';
import { PageNotFound404 } from 'components/404-page';
import { store } from 'redux/store';

import 'styles/globals.scss';

render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<App />} />
          <Route path='*' element={<PageNotFound404 />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
