import { FixedSizeGrid as Grid } from 'react-window';

import { LotCard } from 'components/common';

import { SEARCH_GRID_CONFIG, LOTS_PER_ROW } from './constants';
import { ISearchLotResults, ICellProps } from './types';

import styles from './search-lot-popover.module.scss';

export function SearchLotsResult({ lots }: ISearchLotResults) {
  const searchedLotCell = ({ columnIndex, rowIndex, style: rowStyle }: ICellProps) => {
    const lotIndex = rowIndex * LOTS_PER_ROW + columnIndex;
    const lot = lots[lotIndex];

    return lot ? <li style={rowStyle}>{<LotCard isSearchView lot={lot} />}</li> : null;
  };
  const gridRowCount = Math.ceil(lots.length / LOTS_PER_ROW);

  return (
    <div className={styles['lot-card-search-result']}>
      <ul>
        {lots.length && (
          <Grid
            columnCount={SEARCH_GRID_CONFIG.COLUMNS}
            columnWidth={SEARCH_GRID_CONFIG.COLUMN_WIDTH}
            rowCount={gridRowCount}
            rowHeight={SEARCH_GRID_CONFIG.ROW_HEIGHT}
            height={SEARCH_GRID_CONFIG.HEIGHT}
            width={SEARCH_GRID_CONFIG.WIDTH}
            overscanRowsCount={SEARCH_GRID_CONFIG.OVER_SCAN_ROWS}
          >
            {searchedLotCell}
          </Grid>
        )}
      </ul>
    </div>
  );
}
