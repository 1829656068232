import { useSelector, useDispatch } from 'react-redux';

import { HOT_KEYS_MAP, STATIC_MESSAGES } from 'components/constants';
import { BID_TYPES } from 'components/right-panel/types';
import { useHotKeysOnKeyup } from 'hooks/use-hot-keys-on-keyup';
import { mutateBidAccept } from 'redux/middlewares/live-server/actions';
import { formatCurrency } from 'redux/middlewares/live-server/helpers';
import { brokerActions } from 'redux/slices/broker';
import { selectAskPriceOnServer, selectCurrentAuctionState } from 'redux/slices/broker/selectors';
import { AUCTION_STATES } from 'redux/slices/broker/types';
import { selectCurrencyCode } from 'redux/slices/catalog/selectors';

import { getDynamicFontSizeForBidButtons } from '../helpers';

import styles from './floor-bid-btn.module.scss';

export function FloorBidBtn() {
  const dispatch = useDispatch();

  const currencyCode = useSelector(selectCurrencyCode);
  const askPrice = useSelector(selectAskPriceOnServer);
  const auctionState = useSelector(selectCurrentAuctionState);

  const askPriceAmount = formatCurrency(askPrice);

  const onFloorBidBtnClick = () => {
    if (auctionState === AUCTION_STATES.IN_PROGRESS) {
      dispatch(mutateBidAccept(BID_TYPES.BID_FLOOR, askPrice));
    } else {
      dispatch(brokerActions.displayCustomMessage({ message: STATIC_MESSAGES.START_AUCTION_FIRST }));
    }
  };

  useHotKeysOnKeyup(HOT_KEYS_MAP.ACCEPT_FLOOR_BID, onFloorBidBtnClick, [auctionState, askPrice]);

  return (
    <button className={styles['floor-bid-btn']} onClick={onFloorBidBtnClick}>
      <div className={styles['bid-amount']}>
        <span className={styles['currency']}>{currencyCode}</span>
        <span
          className={styles['amount']}
          style={{ fontSize: getDynamicFontSizeForBidButtons(askPriceAmount?.length) }}
        >
          {askPriceAmount}
        </span>
      </div>
    </button>
  );
}
