import { useSelector } from 'react-redux';

import { SellerConsoleModal } from 'components/common/seller-console-modal';
import { getAuctionReportURL } from 'components/helpers';
import { selectCatalogRef } from 'redux/slices/catalog/selectors';

export function MultipleConnectionsModal() {
  const catalogRef = useSelector(selectCatalogRef);
  const closeUrl = getAuctionReportURL(catalogRef);

  return (
    <SellerConsoleModal
      title='You have been disconnected.'
      description={`Your internet connection has been lost or someone else has opened this seller console.`}
      primaryBtnText='Reconnect Now'
      primaryBtnClickHandler={() => window.location.reload()}
      primaryBtnClass='green'
      secondaryBtnText='Do not reconnect'
      secondaryBtnClickHandler={() => window.location.assign(closeUrl)}
      secondaryBtnClass='link'
    />
  );
}
