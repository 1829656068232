import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { USER_CONNECTION_STATUS } from 'components/constants';
import { BID_EVENTS } from 'redux/middlewares/socket/types';

import { RESET_LOT_MESSAGES_EVENTS } from './constants';
import {
  IMiddlewareAction,
  STOMP_ACTION_TYPES,
  IBroker,
  ICurrentLot,
  TBrokerDisplayMessage,
  AUCTION_STATES,
} from './types';

const initialState: IBroker = {
  currentLotData: null,
  auctionState: AUCTION_STATES.PAUSED,
  displayMessages: [],
  lastEventId: '',
  isConnected: false,
  signInCount: 0,
  connectionStatus: USER_CONNECTION_STATUS.OFFLINE,
  isSellCallInProgress: false,
  errors: {
    connectionFailure: false,
  },
};

export const brokerSlice = createSlice({
  name: 'broker',
  initialState,
  reducers: {
    setIsConnected: state => {
      state.isConnected = true;
    },
    setConnectionError: state => {
      state.errors.connectionFailure = true;
    },
    updateLastEventId: (state, action) => {
      state.lastEventId = action.payload;
    },
    updateCurrentLot: (state, action: PayloadAction<ICurrentLot>) => {
      state.currentLotData = action.payload;
    },
    updateAskPrice: (state, action) => {
      if (state.currentLotData) {
        state.currentLotData.askPrice = action.payload as string;
      }
    },
    updateAuctionState: (state, action: PayloadAction<AUCTION_STATES>) => {
      state.auctionState = action.payload;
    },
    updateSignInCount: (state, action: PayloadAction<number>) => {
      state.signInCount = action.payload;
    },
    setConnectionStatus: (state, action: PayloadAction<USER_CONNECTION_STATUS>) => {
      state.connectionStatus = action.payload;
    },
    addLotMessage: (state, action: PayloadAction<TBrokerDisplayMessage[]>) => {
      const [messageData] = action.payload || {};
      const { event } = messageData || {};

      if (RESET_LOT_MESSAGES_EVENTS.includes(event as BID_EVENTS)) {
        state.displayMessages = [];
      }
      if (action.payload[0]?.message) {
        state.displayMessages = [...action.payload, ...state.displayMessages];
      }
    },
    displayCustomMessage: (state, action: PayloadAction<TBrokerDisplayMessage>) => {
      const { message, isAlert = false } = action.payload;
      state.displayMessages = [{ message, isAlert }, ...state.displayMessages];
    },
    setIsSellCallInProgress: (state, action: PayloadAction<boolean>) => {
      state.isSellCallInProgress = action.payload;
    },
  },
});

export const brokerActions = brokerSlice.actions;
export const brokerReducer = brokerSlice.reducer;

/**
 *  An action to initiate web socket connection.
 */
export const initiateSocketConnection = (): IMiddlewareAction => ({ type: STOMP_ACTION_TYPES.INITIALIZE });
/**
 *  An action to terminate web socket connection.
 */
export const terminateSocketConnection = (): IMiddlewareAction => ({ type: STOMP_ACTION_TYPES.TERMINATE });
