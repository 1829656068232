import { useSelector } from 'react-redux';

import { formatCurrency } from 'redux/middlewares/live-server/helpers';
import { selectCurrencyCode } from 'redux/slices/catalog/selectors';

import { ILotEstimateProps } from './types';

import styles from './lot-estimate.module.scss';

export function LotEstimate({ estimateType, estimateAmount }: ILotEstimateProps) {
  const currency = useSelector(selectCurrencyCode);
  return (
    <div className={styles['lot-estimate']}>
      <span className={styles['estimate-head']}>{`${estimateType} EST:`}</span>
      <span>{currency}</span>
      <span className={styles['estimate-amount']}>{formatCurrency(estimateAmount)}</span>
    </div>
  );
}
