import { useState } from 'react';

import { StopAuctionPopover } from './stop-auction-popover';

import styles from 'components/left-panel/start-stop-pause/start-stop-pause.module.scss';

export const StopAuction = () => {
  const [isStopPopoverOpen, setIsStopPopoverOpen] = useState(false);

  const openStopPopover = () => {
    setIsStopPopoverOpen(true);
  };

  const closeStopPopover = () => {
    setIsStopPopoverOpen(false);
  };

  return (
    <>
      <button onClick={openStopPopover} className={`${styles['btn']} ${styles['red']}`}>
        Stop
      </button>
      {isStopPopoverOpen && <StopAuctionPopover closePopover={closeStopPopover} />}
    </>
  );
};
