import { useDispatch } from 'react-redux';

import { SellerConsoleModal } from 'components/common/seller-console-modal';
import { MUTATE_CLOSE } from 'redux/middlewares/live-server/actions';

import { formatSeconds } from './helpers';
import { ILastLotWarningModalProps } from './types';

export function WarningModal({ closeModal, secondsLeft }: ILastLotWarningModalProps) {
  const dispatch = useDispatch();

  return (
    <SellerConsoleModal
      title='Sale will automatically close soon!'
      description={`This sale will automatically close in ${formatSeconds(secondsLeft)} due to inactivity`}
      primaryBtnText='Close Sale Now'
      primaryBtnClickHandler={() => dispatch(MUTATE_CLOSE)}
      primaryBtnClass='red'
      secondaryBtnText='Return to Sale'
      secondaryBtnClass='link'
      secondaryBtnClickHandler={() => closeModal()}
    />
  );
}
