import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { unescape } from 'underscore';

import { selectDisplayMessages } from 'redux/slices/broker/selectors';

import styles from './live-messages.module.scss';

export function LiveMessages() {
  const displayMessages = useSelector(selectDisplayMessages);

  return (
    <div className={styles['live-message']}>
      {displayMessages?.map(({ id, message, isAlert }, index: number) => (
        <span key={`${index}-${id}`} className={classNames({ [styles['ask-error-message']]: isAlert })}>
          {unescape(message)}
        </span>
      ))}
    </div>
  );
}
