import { useRef, useState } from 'react';

import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';

import { HOT_KEYS_MAP, POSITIVE_NUMBERS_REGEX, STATIC_MESSAGES } from 'components/constants';
import { MAX_DIGITS_FOR_BID_AMOUNTS } from 'components/right-panel/constants';
import { useHotKeysOnKeyup } from 'hooks/use-hot-keys-on-keyup';
import { brokerActions } from 'redux/slices/broker';
import { selectCurrentAuctionState } from 'redux/slices/broker/selectors';
import { AUCTION_STATES } from 'redux/slices/broker/types';

import { DISPLAY_TYPE, IAskAndIncrementNumericFieldProps, KEY_CODES } from './types';

import styles from '../ask/ask.module.scss';

export function AskAndIncrementNumericField({
  value,
  hotkeysToEnableEditMode,
  onBlur,
  onChange,
}: IAskAndIncrementNumericFieldProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const auctionState = useSelector(selectCurrentAuctionState);
  const [displayType, setDisplayType] = useState<DISPLAY_TYPE>(DISPLAY_TYPE.TEXT);

  const handleBlur = (event: { target: EventTarget }) => {
    onBlur(event);
    setDisplayType(DISPLAY_TYPE.TEXT);
  };

  const saveOnEnterKey = (event: { code: string; target: any }) => {
    if (event.code === KEY_CODES.ENTER || event.code === KEY_CODES.NUMPAD_ENTER) {
      event.target.blur();
    }
  };

  const handleChange = (updatedValue: string) => {
    if (auctionState === AUCTION_STATES.IN_PROGRESS) {
      onChange(updatedValue);
    }
  };

  /**
   * Changes the field from text to input
   * @param event KeyboardEvent if the field state is changed via hotkey
   *  OR undefined if it is being changed by clicking it
   */
  const enableEditMode = (event: KeyboardEvent | undefined) => {
    setDisplayType(DISPLAY_TYPE.INPUT);
    setTimeout(() => {
      inputRef?.current?.select();

      // set the hotkey pressed in value of the field in case ask field was focused via hotkey
      if (event && HOT_KEYS_MAP.EDIT_ASK_FIELD.search(event.key) > -1) {
        handleChange(event.key);
      }
    });
  };

  const handleKeyPress = (event: { key: string; preventDefault: () => void }) => {
    if (auctionState === AUCTION_STATES.PAUSED) {
      dispatch(brokerActions.displayCustomMessage({ message: STATIC_MESSAGES.START_AUCTION_FIRST }));
      return;
    }

    if (!POSITIVE_NUMBERS_REGEX.test(event.key)) {
      dispatch(brokerActions.displayCustomMessage({ message: STATIC_MESSAGES.ENTER_ONLY_NUMBERS }));
    }
  };

  const checkIsUserEnteredValidAmounts = (values: { value: string }) => {
    if (values.value.length > MAX_DIGITS_FOR_BID_AMOUNTS) {
      dispatch(brokerActions.displayCustomMessage({ message: STATIC_MESSAGES.MAX_DIGITS_MSG_FOR_ASK_AND_INCREMENT }));
      return false;
    }

    return true;
  };

  useHotKeysOnKeyup(hotkeysToEnableEditMode, enableEditMode, [auctionState]);

  return (
    <NumberFormat
      className={styles['edit-text']}
      value={value}
      thousandSeparator=','
      isNumericString
      allowNegative={false}
      displayType={displayType}
      getInputRef={inputRef}
      autoComplete='off'
      onValueChange={values => handleChange(values.value)}
      onBlur={handleBlur}
      onKeyPress={handleKeyPress}
      onKeyDown={saveOnEnterKey}
      onClick={() => {
        enableEditMode(undefined);
      }}
      decimalScale={0}
      isAllowed={checkIsUserEnteredValidAmounts}
    />
  );
}
