import {
  BID_TYPES,
  BID_TYPE_TEXT,
  TGetBidAcceptPayloadMsgParams,
  TGetUndoBidMsgParams,
} from 'components/right-panel/types';
import { isValueNull } from 'utils';

/**
 * A helper to format a value in currency format.
 * @param value value to be formatted.
 */
export const formatCurrency = (value: string | number) => {
  return value.toLocaleString();
};

/**
 * A helper that returns bidAccept payload message
 * @param lotNumber lot number
 * @param currency currency
 * @param bid bid amount
 * @param paddle paddle number
 * @param bidType bid type
 */
export const getBidAcceptPayloadMsg = ({
  bid,
  paddle,
  bidType,
  currency,
  lotNumber,
}: TGetBidAcceptPayloadMsgParams): string => {
  const bidTypeText = bidType === BID_TYPES.BID_FLOOR ? BID_TYPE_TEXT.BID_FLOOR : BID_TYPE_TEXT.BID_INTERNET;
  const bidAcceptDetails = bidType !== BID_TYPES.BID_FLOOR && !isValueNull(paddle) ? `Paddle # ${paddle}` : bidTypeText;
  return `Lot ${lotNumber} Bid - ${currency} ${formatCurrency(bid)} (${bidAcceptDetails})`;
};

/**
 * A helper that returns undo bid message
 * @param bid bid amount
 * @param currency currency
 * @param lotNumber lot number
 */
export const getUndoBidMsg = ({ bid, currency, lotNumber }: TGetUndoBidMsgParams): string =>
  `Lot ${lotNumber} - ${currency} ${formatCurrency(bid)} - withdrawn`;
