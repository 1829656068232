import { useSelector } from 'react-redux';

import { selectCurrentAuctionState, selectIsAuctionNotYetStarted } from 'redux/slices/broker/selectors';
import { AUCTION_STATES } from 'redux/slices/broker/types';

import { PauseAuction } from './pause';
import { StartAuction } from './start';
import { StopAuction } from './stop';

import styles from './start-stop-pause.module.scss';

export function StartStopPause() {
  const auctionState = useSelector(selectCurrentAuctionState);
  const isAuctionNotYetStarted = useSelector(selectIsAuctionNotYetStarted);
  const isAuctionPaused = auctionState === AUCTION_STATES.PAUSED;
  const isAuctionInProgress = auctionState === AUCTION_STATES.IN_PROGRESS;

  const renderStartStopPauseButtons = () => {
    if (isAuctionNotYetStarted) {
      return <StartAuction copy='Start this auction' additionalClassName='full-width' />;
    }

    if (isAuctionInProgress) {
      return (
        <>
          <StopAuction />
          <PauseAuction />
        </>
      );
    }

    if (isAuctionPaused && !isAuctionNotYetStarted) {
      return (
        <>
          <StopAuction />
          <StartAuction copy='Start' additionalClassName='green' />
        </>
      );
    }
  };

  return <div className={styles['start-stop-pause-buttons']}>{renderStartStopPauseButtons()}</div>;
}
