import { ENV } from 'configs';

export const RADIX_FOR_WS_CONNECTION_IDENTIFIER = 30;

export const WEBSOCKET_ENDPOINTS = {
  BROKER: `${ENV.WEBSOCKET_BROKER_BASE_URL}/am2b/ws`,
  BROKER_SUBSCRIPTION: '/topic/messages',
};

export const INCREASE_RECONNECT_DELAY_AFTER_ATTEMPTS = 10;
export const MS_IN_A_SECOND = 1000;

export const STOMP_CONFIG = {
  INIT_RECONNECTION_DELAY_MS: 3 * MS_IN_A_SECOND,
  HEARTBEAT_INCOMING_MS: 10 * MS_IN_A_SECOND,
  HEARTBEAT_OUTGOING_MS: 0,
  DISCARD_WEBSOCKET: true,
};

export const SOCKET_CONNECTION_ERROR_DISPLAY_DELAY = 65 * MS_IN_A_SECOND;
export const MAX_BROKER_SALE_LOAD_TIME = 60 * MS_IN_A_SECOND;
export const RECONNECT_DELAY_IF_SALE_NOT_FOUND = 5 * MS_IN_A_SECOND;

export const RECONNECTING_TOAST_DELAY_MS = 5 * MS_IN_A_SECOND;

export const SOCKET_CONN_ERROR_SENTRY_MESSAGE = `Unable to connect to the broker after ${SOCKET_CONNECTION_ERROR_DISPLAY_DELAY} milliseconds`;

export const USER_ID_QUERY_PARAM = 'userID';
