import { SellerConsoleModal } from 'components/common/seller-console-modal';

import { getUnauthorizedUserRedirectURL } from './helpers';

export function AuthorizationFailedModal() {
  const queryParams = new URLSearchParams(window.location.search);
  const parentSite = queryParams.get('parentSite') || '';

  const redirectUserToParentSite = () => {
    location.assign(getUnauthorizedUserRedirectURL(parentSite));
  };

  return (
    <SellerConsoleModal
      title='Authorization Failed!'
      description={`You are not authorized to take this action. Please contact your system administrator.`}
      primaryBtnText='Redirect to home page'
      primaryBtnClickHandler={() => redirectUserToParentSite()}
      primaryBtnClass='link'
      secondaryBtnText=''
    />
  );
}
