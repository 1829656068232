import { USER_CONNECTION_STATUS } from 'components/constants';

export type TBrokerDataItem = {
  askPrice: string;
  currentBid: number;
  currency: string;
  currentWinner: string;
  lotNumber: string;
  itemState: string;
  submittedBid: number;
  submittedBidPaddle: string;
  submittedBidType: string;
  approvalId: string;
  signInCount: number;
  paddle: string;
};

export type TCurrentLotData = {
  auctionState: AUCTION_STATES;
  message: string;
  items: TBrokerDataItem[];
};

export type TBrokerDisplayMessage = {
  id?: number;
  event?: string;
  message: string;
  isAlert?: boolean;
};

export interface ICurrentLot {
  lotNumber: string | null;
  askPriceOnServer: string;
  askPrice: string;
  currentBid: number;
  currentWinner: string;
  submittedBid: number;
  submittedBidPaddle: string;
  submittedBidType: string;
  approvalId: string;
  paddle: string;
}

export type TBrokerMessage = {
  id: number;
  event: string;
  data: TCurrentLotData;
};

export interface IBroker {
  currentLotData: ICurrentLot | null;
  displayMessages: TBrokerDisplayMessage[];
  lastEventId: string;
  isConnected: boolean;
  auctionState: AUCTION_STATES;
  signInCount: number;
  connectionStatus: USER_CONNECTION_STATUS;
  isSellCallInProgress: boolean;
  errors: {
    connectionFailure: boolean;
  };
}

export interface IMiddlewareAction {
  type: string;
}

export enum AUCTION_STATES {
  IN_PROGRESS = 'InProgress',
  PAUSED = 'Paused',
}

export enum STOMP_ACTION_TYPES {
  INITIALIZE = 'START_STOMP',
  TERMINATE = 'STOP_STOMP',
}

export enum EVENT_DATA_ITEM_STATES {
  IN_PROGRESS = 'InProgress',
  SOLD = 'Sold',
  DEFAULT = 'Default',
}
