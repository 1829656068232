import { LOTS_TO_DISPLAY_IN_CAROUSEL } from './constants';

/**
 * A helper function to get the lots to be displayed in the carousel
 * If current lot is the first lot, then we display first three lots
 * If current lot is the last lot, then we display last three lots
 * If current lot is in the middle, then we display one previous lot, current lot and one next lot
 * @param lotKeys:string[]
 * @param currentLotIndex
 * @param {number} offset - offset can take any value from [-lotKeys.length, lotKeys.length]
 * @returns TLot[]
 */
export function getKeysOfLotsToDisplay(lotKeys: string[], currentLotIndex: number, offset: number): string[] {
  if (lotKeys.length <= LOTS_TO_DISPLAY_IN_CAROUSEL) return lotKeys;
  switch (currentLotIndex) {
    case -1:
      return [];
    case 0:
      return lotKeys.slice(offset, LOTS_TO_DISPLAY_IN_CAROUSEL + offset);
    case lotKeys.length - 1: {
      const start = lotKeys.length - LOTS_TO_DISPLAY_IN_CAROUSEL + offset;
      const end = lotKeys.length + offset;
      return lotKeys.slice(start, end);
    }
    default: {
      const lotsToShowOnLeft = Math.floor(LOTS_TO_DISPLAY_IN_CAROUSEL / 2);
      const lotsToShowOnRight = Math.ceil(LOTS_TO_DISPLAY_IN_CAROUSEL / 2);
      return lotKeys.slice(currentLotIndex - lotsToShowOnLeft + offset, currentLotIndex + lotsToShowOnRight + offset);
    }
  }
}
