import { useState } from 'react';

import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { STATIC_ASSETS, STATIC_MESSAGES } from 'components/constants';
import { brokerActions } from 'redux/slices/broker';
import { selectCurrentAuctionState } from 'redux/slices/broker/selectors';
import { AUCTION_STATES } from 'redux/slices/broker/types';

import { LotHover } from './lot-hover';
import { SoldLotOverlay } from './sold-lot-overlay';
import { ReopenSoldLotPopover } from './sold-lot-overlay/reopen-sold-lot-popover';
import { ILotCardProps } from './types';

import styles from './lot-card.module.scss';

export function LotCard({ lot, isCurrent, isSearchView }: ILotCardProps) {
  const dispatch = useDispatch();
  const auctionState = useSelector(selectCurrentAuctionState);
  const [showReopenSoldLotPopover, setShowReopenSoldLotPopover] = useState(false);
  const { title, lotNumber, isSold, soldAmount, primaryImage, acceptedBidder, paddle } = lot;
  const lotClasses = classNames(styles['lot-card'], {
    [styles['lot-sold']]: isSold,
    [styles['current-lot']]: isCurrent,
  });

  const reopenSoldLotPopoverClickHandler = () => {
    if (auctionState === AUCTION_STATES.PAUSED) {
      dispatch(brokerActions.displayCustomMessage({ message: STATIC_MESSAGES.START_AUCTION_FIRST }));
      return;
    }
    setShowReopenSoldLotPopover(true);
  };

  const handleReopenSoldPopoverCancel = () => {
    setShowReopenSoldLotPopover(false);
  };

  return (
    <>
      <div className={lotClasses}>
        <span className={styles['title']} dangerouslySetInnerHTML={{ __html: title }} />
        <div className={styles['thumb-img']}>
          <img src={primaryImage.thumbURL || STATIC_ASSETS.NO_IMAGE} alt={title} />
        </div>
        <span className={styles['lot-number']}>{`Lot ${lotNumber}`}</span>
        {isSold ? (
          <SoldLotOverlay
            paddle={paddle}
            soldAmount={soldAmount}
            acceptedBidder={acceptedBidder}
            reopenSoldLotPopoverClickHandler={reopenSoldLotPopoverClickHandler}
          />
        ) : (
          !isCurrent && <LotHover lotNumber={lotNumber} />
        )}
      </div>
      <ReopenSoldLotPopover
        isSearchView={isSearchView}
        lotNumber={lotNumber}
        showReopenSoldLotPopover={showReopenSoldLotPopover}
        handleReopenSoldPopoverCancel={handleReopenSoldPopoverCancel}
      />
    </>
  );
}
