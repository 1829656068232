import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { StopAuctionModal } from 'components/left-panel/start-stop-pause/stop/stop-auction-modal';
import { useModal } from 'hooks/useModal';
import { MUTATE_CLOSE, mutateSell } from 'redux/middlewares/live-server/actions';
import { selectCurrentBid } from 'redux/slices/broker/selectors';

import { IStopAuctionPopover } from './types';

import styles from './stop-auction-popover.module.scss';

export const StopAuctionPopover = ({ closePopover }: IStopAuctionPopover) => {
  const dispatch = useDispatch();
  const currentBid = useSelector(selectCurrentBid);

  const { isOpenModal: isStopModalOpen, openModal: openStopModal, closeModal: closeStopModal } = useModal();
  const [isSellAndClose, setIsSellAndClose] = useState(false);

  const handlePassSellContinue = (isSell: boolean) => {
    setIsSellAndClose(isSell);
    openStopModal();
  };

  const handleCloseAuction = () => {
    if (isSellAndClose) {
      dispatch(mutateSell({ nextAction: MUTATE_CLOSE, triggerNextActionOnSuccessfulSell: false }));
    } else {
      dispatch(MUTATE_CLOSE);
    }
  };

  const handleModalClose = () => {
    closeStopModal();
    closePopover();
  };

  return (
    <>
      <div className={styles['stop-auction-popover']}>
        <button onClick={closePopover} className={styles['close-popover-btn']} />
        <div className={styles['confirmation-content']}>
          <p>You are about to permanently end this auction.</p>
          <p>Continue?</p>
          <button onClick={() => handlePassSellContinue(false)} className={`${styles['btn']} ${styles['gray']}`}>
            Pass / Continue
          </button>
          {currentBid > 0 && (
            <button onClick={() => handlePassSellContinue(true)} className={`${styles['btn']} ${styles['gray']}`}>
              Sell / Continue
            </button>
          )}
          <button onClick={closePopover} className={`${styles['btn']} ${styles['green']}`}>
            Cancel
          </button>
        </div>
      </div>
      {isStopModalOpen && <StopAuctionModal closeModal={handleModalClose} closeAuction={handleCloseAuction} />}
    </>
  );
};
