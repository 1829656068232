import { useDispatch } from 'react-redux';

import { MUTATE_STOP } from 'redux/middlewares/live-server/actions';

import styles from './start-stop-pause.module.scss';

export function PauseAuction() {
  const dispatch = useDispatch();

  const handlePauseAuction = () => {
    dispatch(MUTATE_STOP);
  };

  return (
    <button onClick={handlePauseAuction} className={`${styles['btn']} ${styles['yellow']}`}>
      Pause
    </button>
  );
}
