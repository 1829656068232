import { selectCurrentLot, selectTotalSoldLots } from 'redux/slices/catalog/selectors';
import { RootState } from 'redux/store';

import { AUCTION_STATES, TBrokerDisplayMessage } from './types';

/**
 * A function that selects the list of displayMessages in the store.
 * @param {RootState} state of store
 * @returns {TBrokerDisplayMessage} list of displayMessages
 */
export const selectDisplayMessages = (state: RootState): TBrokerDisplayMessage[] => state.broker.displayMessages;

/**
 * A function that selects the current lot number in the store.
 * @param {RootState} state of store
 * @returns {string} lot number
 */
export const selectCurrentLotNumber = (state: RootState): string => state.broker.currentLotData?.lotNumber || '';

/**
 * A function that selects the ask price on the live server for the current lot.
 * @param {RootState} state of store
 * @returns {string} ask price
 */
export const selectAskPriceOnServer = (state: RootState): number =>
  Number(state.broker.currentLotData?.askPriceOnServer) || 0;

/**
 * A function that selects the current ask price for the current lot.
 * NOTE: It syncs with the user input in ask field on the console.
 * @param {RootState} state of store
 * @returns {string} ask price
 */
export const selectAskPrice = (state: RootState): string => state.broker.currentLotData?.askPrice || '';

/**
 * A function that selects the current bid amount for a current lot in the store.
 * @param {RootState} state of store
 * @returns {number} current bid amount
 */
export const selectCurrentBid = (state: RootState): number => state.broker.currentLotData?.currentBid || 0;

/**
 * @function selectCurrentAuctionState - A function that selects the current state of auction in the store.
 * @param {RootState} state of store
 * @returns {string} auction state
 */
export const selectCurrentAuctionState = (state: RootState): string => state.broker.auctionState;

/**
 * A function that selects the loading state of the socket.
 * @param {RootState} state of store
 * @returns {boolean} is socket connected
 */
export const selectIsSocketConnected = (state: RootState): boolean => state.broker.isConnected;

/**
 * A function that selects the submitted bid of current lot
 * @param {RootState} state of store
 * @returns {number} submitted bid
 */
export const selectSubmittedBid = (state: RootState): number => state.broker.currentLotData?.submittedBid || 0;

/**
 * A function that selects the submitted paddle of current lot
 * @param {RootState} state of store
 * @returns {string} submitted bid paddle
 */
export const selectSubmittedBidPaddle = (state: RootState): string =>
  state.broker.currentLotData?.submittedBidPaddle || '';

/**
 * A function that selects the accepted paddle of current lot
 * @param {RootState} state of store
 * @returns {string} accepted bid paddle
 */
export const selectAcceptedBidPaddle = (state: RootState): string => state.broker.currentLotData?.paddle || '';

/**
 * A function that selects the submitted bid type of current lot
 * @param {RootState} state of store
 * @returns {string} submitted bid type
 */
export const selectSubmittedBidType = (state: RootState): string => state.broker.currentLotData?.submittedBidType || '';

/**
 * A function that selects the approval id of current lot
 * @param {RootState} state of store
 * @returns {string} approval id
 */
export const selectApprovalId = (state: RootState): string => state.broker.currentLotData?.approvalId || '';

/**
 * A function that returns true if the auction is not yet started else it returns false.
 * @param {RootState} state of store
 * @returns {boolean}
 */
export const selectIsAuctionNotYetStarted = (state: RootState): boolean => {
  const isAnyLotSold = selectTotalSoldLots(state) > 0;
  const isAuctionPaused = selectCurrentAuctionState(state) === AUCTION_STATES.PAUSED;
  const orderIndex = selectCurrentLot(state)?.orderIndex;
  const currentBid = selectCurrentBid(state);

  return isAuctionPaused && orderIndex === 0 && Number(currentBid) === 0 && !isAnyLotSold;
};

/**
 * A function that selects the sign in count
 * @param {RootState} state of store
 * @returns {number} sign in count
 */
export const selectSignInCount = (state: RootState): number => state.broker.signInCount || 0;

/**
 * A function that selects internet connect status
 * @param {RootState} state of store
 * @returns {string} connection status
 */
export const selectConnectionStatus = (state: RootState): string => state.broker.connectionStatus;

/**
 * A function that return true if the socket connection failed
 * @param {RootState} state of store
 * @returns {string} connection status
 */
export const selectIsSocketConnectionError = (state: RootState): boolean => state.broker.errors.connectionFailure;

/**
 * A function that return true if a sell is in progress
 * @param {RootState} state of store
 * @returns {boolean} true if a sell is in progress, false otherwise.
 */
export const selectIsSellCallInProgress = (state: RootState): boolean => state.broker.isSellCallInProgress;
