import { AUCTION_STATES, TBrokerDisplayMessage, TBrokerMessage, TCurrentLotData } from 'redux/slices/broker/types';
import { getSubStringBetweenStartAndEndToken } from 'utils';

import { formatCurrency } from '../live-server/helpers';
import { RADIX_FOR_WS_CONNECTION_IDENTIFIER, WEBSOCKET_ENDPOINTS } from './constants';
import { BID_EVENTS } from './types';

/**
 * @function getParsedBrokerMessage - parses the event stream message to TBrokerMessage object
 * after manipulating event id, event name and lot data.
 * @param {string}  [message="id: 0\nevent: lot\ndata: {"auctionId":"7RQVL2WRO1"...}"] - JSON parsed
 *  string from eventstream of StompJs
 * @returns {TBrokerMessage} parsed TBrokerMessage object
 */
export const getParsedBrokerMessage = (message: string): TBrokerMessage => {
  const ID_START_TOKEN = 'id: ';
  const EVENT_START_TOKEN = 'event: ';
  const ID_AND_EVENT_END_TOKEN = '\n';
  const DATA_START_TOKEN = 'data: ';

  const eventId = getSubStringBetweenStartAndEndToken(message, ID_START_TOKEN, ID_AND_EVENT_END_TOKEN);
  const event = getSubStringBetweenStartAndEndToken(message, EVENT_START_TOKEN, ID_AND_EVENT_END_TOKEN);
  const data = JSON.parse(getSubStringBetweenStartAndEndToken(message, DATA_START_TOKEN));

  return {
    id: Number(eventId) || 0,
    event: event,
    data: data,
  };
};

/**
 * A helper that returns a unique wsConnectionIdentifier, it would check for the identifier
 * in the session storage first if its not in the session storage it would generate a new one,
 * save it to session storage and return it
 * @param catalogRef : catalog reference
 * @returns A unique alphanumeric string that is used to identify websocket connection
 */
export const getWSConnectionIdentifier = (catalogRef: string): string => {
  let wsConnectionIdentifier = sessionStorage.getItem(`${catalogRef}_ws_connection_identifier`);
  if (!wsConnectionIdentifier) {
    wsConnectionIdentifier = (Math.random() + 1).toString(RADIX_FOR_WS_CONNECTION_IDENTIFIER).substring(2);
    sessionStorage.setItem(`${catalogRef}_ws_connection_identifier`, wsConnectionIdentifier);
  }
  return wsConnectionIdentifier;
};

/**
 * A helper function to get current lot information.
 * @param lotData: data to be compiled into specific lot information
 * @returns: returns current lot information
 */
export const getCurrentLotData = (lotData: TCurrentLotData) => {
  const {
    lotNumber,
    currentBid,
    askPrice,
    currentWinner,
    submittedBid,
    submittedBidPaddle,
    submittedBidType,
    approvalId,
    paddle,
  } = lotData?.items[0] || {};

  return {
    askPriceOnServer: askPrice,
    askPrice,
    lotNumber,
    approvalId,
    currentBid,
    submittedBid,
    currentWinner,
    submittedBidType,
    submittedBidPaddle,
    paddle,
  };
};

/**
 * A helper function to get paddle and lotNumber from current lot data
 * @param lotData: current lot data
 * @returns: returns paddle information and lotNumber
 */
export const getPaddleData = (lotData: TCurrentLotData) => {
  const { lotNumber, paddle } = lotData?.items[0] || {};
  return {
    lotNumber,
    paddle,
  };
};

/**
 * A helper function to get the lot messages
 * @param lotData: data to be compiled into the lot messages
 * @returns: returns a list of lot messages
 */
export const getLotMessages = (lotData: TBrokerMessage) => {
  const { id, event } = lotData || {};
  const { message, auctionState } = lotData?.data || {};
  const { lotNumber, currency, askPrice } = lotData?.data?.items[0] || {};
  const lotMessages: TBrokerDisplayMessage[] = [];

  switch (event) {
    case BID_EVENTS.LOT:
      if (auctionState === AUCTION_STATES.PAUSED) {
        lotMessages.push({ id, event, message: message });
      }
      if (auctionState === AUCTION_STATES.IN_PROGRESS) {
        lotMessages.push({
          id,
          event,
          message: `Lot ${lotNumber} open for bidding`,
        });
      }
      break;
    case BID_EVENTS.START:
      lotMessages.push({ id, event, message: message?.split('</li')[0] });
      break;
    case BID_EVENTS.UNDO_BID:
      lotMessages.push({ id, event, message: `New ask price: ${currency} ${formatCurrency(askPrice)}` });
      lotMessages.push({ id, event, message: message });
      break;
    case BID_EVENTS.NEXT_UNSOLD:
    case BID_EVENTS.UNDOSELL:
      lotMessages.push({ id, event, message: `Lot ${lotNumber} open for bidding` });
      lotMessages.push({ id, event, message: message });
      break;
    case BID_EVENTS.STOP:
    case BID_EVENTS.ASK:
    case BID_EVENTS.SELL:
    case BID_EVENTS.MESSAGE:
      lotMessages.push({ id, event, message: message });
      break;
  }

  return lotMessages;
};

/**
 * A helper function to return the socket destination string.
 *
 * To make it easier to identify the different users that are connecting, add context to the topic subscription id.
 * This function returns 'BROKER_SUBSCRIPTION/catalogRef-SC-userID-random string' if userId is present
 * else it returns 'BROKER_SUBSCRIPTION/catalogRef-SC-random string'
 * @param catalogRef
 * @param userID
 */
export const getSocketDestination = (catalogRef: string, userID: string | null) => {
  const wsConnectionIdentifier = getWSConnectionIdentifier(catalogRef);
  if (userID) {
    const userIdentifier = userID.split('-')[0];
    return `${WEBSOCKET_ENDPOINTS.BROKER_SUBSCRIPTION}/${catalogRef}-SC-${userIdentifier}-${wsConnectionIdentifier}`;
  } else {
    return `${WEBSOCKET_ENDPOINTS.BROKER_SUBSCRIPTION}/${catalogRef}-SC-${wsConnectionIdentifier}`;
  }
};
