import { STATIC_MESSAGES } from 'components/constants';
import { AUCTION_STATES } from 'redux/slices/broker/types';

/**
 * @function getRevertBidErrorMessage - A helper function to get error message for bid revert
 * @param {string} auctionState auction state
 * @param {number} currentBid current bid on lot
 * @returns {string} error message to show
 */
export const getRevertBidErrorMessage = (auctionState: string, currentBid: number): string => {
  let message = '';
  if (!currentBid) {
    message = STATIC_MESSAGES.NO_BID;
  } else if (auctionState === AUCTION_STATES.PAUSED) {
    message = STATIC_MESSAGES.START_AUCTION_FIRST;
  }
  return message;
};
