import { TLot } from 'types';

/**
 * A helper function that searches for the lots containing the given search string in the lot title and lot number.
 * @param {Map<string, TLot>} lots
 * @param {string} searchString
 * @returns {Array} lots
 */
export function searchLots(lots: Map<string, TLot>, searchString: string): TLot[] {
  const searchResultLots: TLot[] = [];
  const searchStringLowerCase = searchString.toLowerCase();

  lots.forEach(lot => {
    if (
      lot.lotNumber.toLowerCase().includes(searchStringLowerCase) ||
      lot.title.toLowerCase().includes(searchStringLowerCase)
    ) {
      searchResultLots.push(lot);
    }
  });
  return searchResultLots;
}
