import { useState } from 'react';

/**
  A common hook to handle modal in NSC
*/
export function useModal() {
  const [isOpenModal, setIsOpenModal] = useState(false);

  /**
   A function which opens the modal
 */
  const openModal = () => {
    setIsOpenModal(true);
  };

  /**
   A function which closes the modal
 */
  const closeModal = () => {
    setIsOpenModal(false);
  };

  return { isOpenModal, openModal, closeModal };
}
