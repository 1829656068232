import { ENV } from 'configs';

import { LIVE_SERVER_COMMANDS } from './constants';

/**
 * Returns Live Server URL with the given command.
 * @param catalogRef
 * @param command
 * @param queryParams query parameters strings e.g., 'v2=true&version=2'
 */
export const getLiveServerCommandURL = (
  catalogRef: string,
  command: LIVE_SERVER_COMMANDS,
  queryParams?: string,
): string => {
  const URL = `${ENV.LIVE_SERVER_COMMANDS_BASE_URL}/am2/ltc/${catalogRef}/${command}`;
  return queryParams ? `${URL}?${queryParams}` : URL;
};
