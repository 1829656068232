import { useSelector } from 'react-redux';

import { STATIC_ASSETS } from 'components/constants';
import { selectCurrentLot } from 'redux/slices/catalog/selectors';

import { LotEstimate } from './lot-estimate';

import styles from './current-lot-info.module.scss';

export function CurrentLotInfo() {
  const currentLot = useSelector(selectCurrentLot);

  return (
    <div className={styles['current-lot-info']}>
      {currentLot && (
        <>
          <div className={styles['lot-img']}>
            <img src={currentLot.primaryImage.thumbURL || STATIC_ASSETS.NO_IMAGE} alt='logo' />
          </div>
          <div className={styles['lot-number']}>
            <span>{`Lot No.${currentLot.lotNumber}`}</span>
          </div>
          <div className={styles['lot-title']}>
            <span dangerouslySetInnerHTML={{ __html: currentLot.title }}></span>
          </div>

          <LotEstimate estimateType='LOW' estimateAmount={currentLot.estimateLow} />
          <LotEstimate estimateType='HIGH' estimateAmount={currentLot.estimateHigh} />
        </>
      )}
    </div>
  );
}
