import { ENVIRONMENTS } from 'components/constants';
import { isValidURL } from 'components/helpers';
import { ENV } from 'configs';

import { DEFAULT_AUCTION_URL } from './constants';

/**
 * A helper to return the redirect URL for an unauthorized user
 * @param {string} parentSiteURL url from where user came from
 * @returns {string} return the redirect url to take user to parent site
 */
export const getUnauthorizedUserRedirectURL = (parentSiteURL: string) => {
  const subDomain = ENV.DEPLOYMENT_ENVIRONMENT === ENVIRONMENTS.PRODUCTION ? 'www' : 'stage';

  if (parentSiteURL && isValidURL(parentSiteURL)) {
    return parentSiteURL;
  }

  return `https://${subDomain}.${DEFAULT_AUCTION_URL}`;
};
