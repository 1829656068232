import { useDispatch } from 'react-redux';

import { MUTATE_START_AUCTION } from 'redux/middlewares/live-server/actions';

import { IStartAuctionProps } from './types';

import styles from '../start-stop-pause.module.scss';

export function StartAuction({ copy, additionalClassName }: IStartAuctionProps) {
  const dispatch = useDispatch();

  const handleStartAuction = () => {
    dispatch(MUTATE_START_AUCTION);
  };

  return (
    <button className={`${styles['btn']} ${styles[additionalClassName]}`} onClick={handleStartAuction}>
      {copy}
    </button>
  );
}
