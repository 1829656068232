/* eslint-disable */
// @ts-nocheck
// Disabling linters because of the 3rd party salesforce JS scripts

import Script from 'next/script';

import { SALESFORCE_CLIENT_ID, SALESFORCE_DEPLOYMENT_ID, SALESFORCE_ORGANIZATION_ID } from './constants';

import './salesforce-live-agent.scss';

export function SalesforceLiveAgent() {
  const initializeEmbeddedService = () => {
    const initESW = gslbBaseURL => {
      embedded_svc.settings.displayHelpButton = true;
      embedded_svc.settings.language = '';
      embedded_svc.settings.enabledFeatures = ['LiveAgent'];
      embedded_svc.settings.entryFeature = 'LiveAgent';
      embedded_svc.init(
        'https://invaluable.my.salesforce.com',
        'https://auctionhelp.secure.force.com/auctionzip',
        gslbBaseURL,
        SALESFORCE_ORGANIZATION_ID,
        'Initial_Chat_Team',
        {
          baseLiveAgentContentURL: 'https://c.la4-c1-ph2.salesforceliveagent.com/content',
          deploymentId: SALESFORCE_DEPLOYMENT_ID,
          buttonId: '5734T000000Cwen',
          baseLiveAgentURL: 'https://d.la4-c1-ph2.salesforceliveagent.com/chat',
          eswLiveAgentDevName: 'Initial_Chat_Team',
          isOfflineSupportEnabled: true,
        },
      );
    };
    if (!window.embedded_svc) {
      const script = document.createElement('script');
      script.src = 'https://invaluable.my.salesforce.com/embeddedservice/5.0/esw.min.js';
      document.body.appendChild(script);
      script.onload = () => {
        initESW(null);
      };
    } else {
      initESW('https://service.force.com');
    }
  };

  const initializeAgent = () => {
    liveagent.init(
      'https://d.la4-c1-ph2.salesforceliveagent.com/chat',
      SALESFORCE_DEPLOYMENT_ID,
      SALESFORCE_ORGANIZATION_ID,
    );
  };

  const initializeChat = () => {
    liveagent.startChat(SALESFORCE_CLIENT_ID);
  };

  return (
    <>
      <Script
        src='https://c.la4-c1-ph2.salesforceliveagent.com/content/g/js/54.0/deployment.js'
        onLoad={initializeAgent}
      />
      <Script src='https://service.force.com/embeddedservice/5.0/esw.min.js' onLoad={initializeEmbeddedService} />
      <button
        id={`liveagent_button_online_${SALESFORCE_CLIENT_ID}`}
        style={{ display: 'none' }}
        onClick={initializeChat}
      />
      <div id={`liveagent_button_offline_${SALESFORCE_CLIENT_ID}`} style={{ display: 'none' }} />
    </>
  );
}
