import { Ask, Increment } from './ask-increment';
import { CurrentBidHighBidder } from './current-bid-high-bidder';
import { FloorBidBtn } from './floor-bid-btn';
import { InternetBidBtn } from './internet-bid-btn';
import { OnlineParticipants } from './online-participants';
import { PassSellLot } from './pass-sell-lot';
import { SendMessagesRevertBid } from './send-msgs-revert-bid';

import styles from './right-panel.module.scss';

export function RightPanel() {
  return (
    <div className={styles['right-panel']}>
      <FloorBidBtn />
      <InternetBidBtn />
      <SendMessagesRevertBid />

      <div className={styles['current-lot-status']}>
        <Ask />
        <Increment />
        <CurrentBidHighBidder />
        <OnlineParticipants />
      </div>

      <PassSellLot />
    </div>
  );
}
