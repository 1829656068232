import { useSelector, useDispatch } from 'react-redux';

import { HOT_KEYS_MAP } from 'components/constants';
import { useHotKeysOnKeyup } from 'hooks/use-hot-keys-on-keyup';
import { MUTATE_UNDO_BID } from 'redux/middlewares/live-server/actions';
import { brokerActions } from 'redux/slices/broker';
import { selectCurrentAuctionState, selectCurrentBid } from 'redux/slices/broker/selectors';

import { getRevertBidErrorMessage } from './helpers';

import styles from '../send-msgs-revert-bid.module.scss';

export function RevertBid() {
  const dispatch = useDispatch();

  const currentBid = useSelector(selectCurrentBid);
  const auctionState = useSelector(selectCurrentAuctionState);

  const onRevertBidButtonClick = () => {
    const errorMessage = getRevertBidErrorMessage(auctionState, currentBid);
    if (errorMessage) {
      dispatch(brokerActions.displayCustomMessage({ message: errorMessage }));
    } else {
      dispatch(MUTATE_UNDO_BID);
    }
  };

  useHotKeysOnKeyup(HOT_KEYS_MAP.REVERT_BID, onRevertBidButtonClick, [auctionState, currentBid]);

  return (
    <button onClick={onRevertBidButtonClick} className={`${styles['btn']} ${styles['red']}`}>
      <span>
        revert <br /> last Bid
      </span>
    </button>
  );
}
