import { useSelector } from 'react-redux';

import { STATIC_MESSAGES } from 'components/constants';
import { selectIsAuctionNotYetStarted } from 'redux/slices/broker/selectors';
import { selectCatalogLots, selectCurrentLot, selectTotalSoldLots } from 'redux/slices/catalog/selectors';

import styles from './progress-bar.module.scss';

export function ProgressBar() {
  const totalSoldLots = useSelector(selectTotalSoldLots);
  const totalLots = useSelector(selectCatalogLots).size;
  const currentLot = useSelector(selectCurrentLot);
  const isAuctionNotYetStarted = useSelector(selectIsAuctionNotYetStarted);
  // As the orderIndex starts from 0 and the text (Lot 0 of 21) looks weird, therefor adding 1 to its value to start the lot index from 1
  const currentLotIndex = (currentLot && currentLot.orderIndex + 1) || -1;
  const soldLotsPercent = Math.round((totalSoldLots / totalLots) * 100);
  const progressBarValue = isAuctionNotYetStarted ? 0 : Math.round((currentLotIndex / totalLots) * 100);

  return (
    <div className={styles['progress-bar']}>
      <div className={styles['progress-bar-text']}>
        <span>
          {isAuctionNotYetStarted
            ? STATIC_MESSAGES.AUCTION_NOT_STARTED
            : `${currentLotIndex} of ${totalLots} (${soldLotsPercent}% of items sold)`}
        </span>
      </div>
      <div className={styles['progress-bar-meter']} style={{ width: `${progressBarValue}%` }} />
    </div>
  );
}
