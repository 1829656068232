import { useEffect } from 'react';

import TagManager from 'react-gtm-module';

import { ENV } from 'configs';

/**
 * A hook to to initialize google tag manager for the application
 */
export function useGTM(): void {
  useEffect(() => {
    const tagManagerArgs = { gtmId: ENV.GOOGLE_TAG_MANAGER_ID };
    TagManager.initialize(tagManagerArgs);
  }, []);
}
