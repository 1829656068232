/**
 * This file allows accessing environment variables from
 * a central place in the codebase.
 *
 * It also helps in setting up default values for env-
 * variables in case they are omitted from the actual
 * env files. */

export const ENV = {
  RESTFUL_BASE_URL: process.env.REACT_APP_RESTFUL_BASE_URL || '',
  LIVE_SERVER_COMMANDS_BASE_URL: process.env.REACT_APP_LIVE_SERVER_COMMANDS_BASE_URL || '',
  SENTRY_DSN_KEY: process.env.REACT_APP_SENTRY_DSN_KEY || '',
  SENTRY_ENVIRONMENT: process.env.REACT_APP_SENTRY_ENVIRONMENT || '',
  RELEASE_NUMBER: process.env.REACT_APP_RELEASE_NUMBER || '',
  SENTRY_RELEASE_VERSION: process.env.REACT_APP_SENTRY_RELEASE_VERSION || '',
  IS_LOCAL: !process.env.NODE_ENV || process.env.NODE_ENV === 'development',
  WEBSOCKET_BROKER_BASE_URL: process.env.REACT_APP_WEBSOCKET_BROKER_BASE_URL || '',
  DEPLOYMENT_ENVIRONMENT: process.env.REACT_APP_DEPLOYMENT_ENVIRONMENT || '',
  GOOGLE_TAG_MANAGER_ID: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID || '',
};
