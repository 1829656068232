import { LARGE_BID_TEXT_LENGTH, MEDIUM_BID_TEXT_LENGTH } from './constants';
import { BID_BUTTON_FONT_SIZE } from './types';

/**
 * A helper to get dynamic font size based on bid button's text length. If length of the text is greater than 8 digits then
 * we are lowering the font size for fixing the UI otherwise we will use same font size.
 * @param {string} textLength length of the button's text
 * @returns {string} returns the dynamic font size
 */
export const getDynamicFontSizeForBidButtons = (textLength: number) => {
  if (textLength > LARGE_BID_TEXT_LENGTH) {
    return BID_BUTTON_FONT_SIZE.SMALL;
  } else if (textLength > MEDIUM_BID_TEXT_LENGTH) {
    return BID_BUTTON_FONT_SIZE.MEDIUM;
  }
  return BID_BUTTON_FONT_SIZE.DEFAULT;
};
