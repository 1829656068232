import { BIDDER_TYPES_TO_DISPLAY } from 'redux/types';
import { TLot } from 'types';

import { BIDDER_TYPE } from './types';

/**
 * A helper function to get the catalogRef from the URL
 * @returns {string} The catalogRef
 */
export const fetchCatalogRefFromURL = () => new URLSearchParams(location.search).get('catalogRef')?.toUpperCase() || '';

/**
 * A helper function to get the accepted bidder from current winner
 * @returns {string} accepted bidder | ''
 */
export const getAcceptedBidder = (currentWinner: string): string => {
  if (currentWinner) {
    return currentWinner === BIDDER_TYPE.FLOOR
      ? BIDDER_TYPES_TO_DISPLAY.FLOOR_BIDDER
      : BIDDER_TYPES_TO_DISPLAY.INTERNET_BIDDER;
  }
  return '';
};

export const defaultLotValues: TLot = {
  lotID: -1,
  lotRef: '',
  lotNumber: '',
  title: '',
  description: '',
  estimateLow: -1,
  estimateHigh: -1,
  orderIndex: -1,
  isSold: false,
  soldAmount: -1,
  currentWinnerType: '',
  primaryImage: {},
  acceptedBidder: '',
  paddle: '',
};
