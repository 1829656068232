export interface IAskAndIncrementNumericFieldProps {
  value: string | number;
  hotkeysToEnableEditMode: string;
  onBlur: (e: any) => void;
  onChange: (updatedValue: string) => void;
}

export enum KEY_CODES {
  ENTER = 'Enter',
  NUMPAD_ENTER = 'NumpadEnter',
}

export enum DISPLAY_TYPE {
  TEXT = 'text',
  INPUT = 'input',
}
