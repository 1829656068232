import { useSelector } from 'react-redux';

import { selectSignInCount } from 'redux/slices/broker/selectors';

import styles from './online-participants.module.scss';

export function OnlineParticipants() {
  const signInCount = useSelector(selectSignInCount);

  return (
    <div className={styles['online-participants']}>
      <span>Online Participants:</span>
      <span>{signInCount}</span>
    </div>
  );
}
