import { configureStore } from '@reduxjs/toolkit';
import { enableMapSet } from 'immer';

import { socketMiddleware, liveServerEndpointsMiddleware } from './middlewares';
import { auctionInfoReducer } from './slices/auction-info';
import { brokerReducer } from './slices/broker';
import { catalogReducer } from './slices/catalog';

/* We need to enable Map and Set usage in redux store as they are not serializable and hence not
supported by default. For more details go to:
https://redux.js.org/faq/organizing-state#can-i-put-functions-promises-or-other-non-serializable-items-in-my-store-state */
enableMapSet();
export const store = configureStore({
  reducer: {
    broker: brokerReducer,
    catalog: catalogReducer,
    auctionInfo: auctionInfoReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([socketMiddleware, liveServerEndpointsMiddleware]),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
