import { USER_CONNECTION_STATUS } from 'components/constants';

import { TAlertConfig } from './types';

export const ALERT_CONFIG: TAlertConfig = {
  [USER_CONNECTION_STATUS.CONNECTED]: {
    text: 'Your device is connected to the internet.',
    class: 'success',
  },
  [USER_CONNECTION_STATUS.RECONNECTING]: {
    text: 'Attempting to reconnect...',
    class: 'warning',
  },
  [USER_CONNECTION_STATUS.OFFLINE]: {
    text: 'You have lost your internet connection.',
    class: 'danger',
  },
};

export const CONNECTED_TOAST_DURATION_MS = 3000;
