import { useDispatch, useSelector } from 'react-redux';

import { STATIC_MESSAGES } from 'components/constants';
import { getSoldLotMessage } from 'components/helpers';
import { mutateNextUnsold, mutateSell } from 'redux/middlewares/live-server/actions';
import { brokerActions } from 'redux/slices/broker';
import { selectCurrentBid, selectCurrentLotNumber } from 'redux/slices/broker/selectors';

/**
   A common hook used to sell the current lot and open the next lot if there is a current bid.
   Otherwise, it shows a [no bid] message.
 */
export const useSellNext = () => {
  const dispatch = useDispatch();
  const currentBid = useSelector(selectCurrentBid);
  const currentLotNumber = useSelector(selectCurrentLotNumber);

  return () => {
    if (currentBid > 0) {
      const payloadForNextAction = { nextAction: mutateNextUnsold(getSoldLotMessage(currentLotNumber)) };
      dispatch(mutateSell(payloadForNextAction));
    } else {
      dispatch(brokerActions.displayCustomMessage({ message: STATIC_MESSAGES.NO_BID }));
    }
  };
};
