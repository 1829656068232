import { createAsyncThunk } from '@reduxjs/toolkit';

import { selectCatalogRef } from 'redux/slices/catalog/selectors';
import { RootState } from 'redux/store';
import { LiveServerService } from 'services/live-server';
import { SELLER_CONSOLE_USER } from 'services/live-server/constants';

import { selectSellerId } from './selectors';
import { IUserAuthorizationParams, IAuctionInfo } from './types';

export const fetchAuctionInfo = createAsyncThunk(
  'auctionInfo/fetch',
  async (userAuthorizationParams: IUserAuthorizationParams, thunkAPI) => {
    const rootState = thunkAPI.getState() as RootState;
    const catalogRef = selectCatalogRef(rootState);
    const sellerId = selectSellerId(rootState);
    const { houseId, userId } = userAuthorizationParams || {};
    const liveServerService = new LiveServerService(catalogRef, sellerId, SELLER_CONSOLE_USER);
    const response = await liveServerService.getAuctionInfo({ houseId, userId });

    if (!response.success) {
      return thunkAPI.rejectWithValue({
        statusCode: response.statusCode,
        statusMessage: response.statusMessage,
      });
    }

    return response.data as IAuctionInfo;
  },
);
