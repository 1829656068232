import { useSelector } from 'react-redux';

import { useSentryApiErrorLogging } from 'hooks/use-sentry-api-error-logging';
import { selectIsConnectConnectionError } from 'redux/slices/auction-info/selectors';
import { selectIsSocketConnectionError } from 'redux/slices/broker/selectors';
import { selectIsCatalogConnectionError } from 'redux/slices/catalog/selectors';

import styles from './page-loading-overlay.module.scss';

export function PageLoadingOverlay() {
  const isConnectError = useSelector(selectIsConnectConnectionError);
  const isCatalogError = useSelector(selectIsCatalogConnectionError);
  const isSocketError = useSelector(selectIsSocketConnectionError);
  const isConnectionError = isConnectError || isCatalogError || isSocketError;
  const troubleshootSellerConsoleLink =
    'https://www.invaluableauctions.com/inv/help-center/resource/running-a-live-auction/using-the-seller-console/';

  useSentryApiErrorLogging(isConnectError, isCatalogError, isSocketError);

  return (
    <div className={styles['loading-overlay-holder']}>
      <div className={styles['loading-overlay-body']}>
        <h2>{`We're setting up your auction.`}</h2>
        {isConnectionError ? (
          <h3 className={styles['error-text']}>
            Console failed to load. Please try again or contact our customer care representative.
          </h3>
        ) : (
          <h3>This may take a few minutes. Thank you for waiting.</h3>
        )}
        <p>
          If this page is loading for longer than usual, it could be that anti-virus programs on your computer are
          preventing the seller console connection.
          <a href={troubleshootSellerConsoleLink} target='_blank' rel='noreferrer'>
            See more info here
          </a>
        </p>
        {!isConnectionError && (
          <div className={styles['spinner-holder']}>
            <div className={styles['spinner']} />
          </div>
        )}
        <div className={styles['logos']}>
          <img src='/images/cobrand-logo.png' alt='logo' />
        </div>
      </div>
    </div>
  );
}
