import { useState } from 'react';

import { useDispatch } from 'react-redux';

import { HOT_KEYS_MAP } from 'components/constants';
import { useHotKeysOnKeyup } from 'hooks/use-hot-keys-on-keyup';
import { mutateMessage } from 'redux/middlewares/live-server/actions';

import { PREDEFINED_MESSAGES } from './constants';
import { CustomMessagePopover } from './popover';

import styles from '../send-msgs-revert-bid.module.scss';
export function CustomMessages() {
  const dispatch = useDispatch();
  const [isPopoverShown, setIsPopoverShown] = useState(false);
  const [customMessageInput, setCustomMessageInput] = useState('');
  const [messages, setMessages] = useState(PREDEFINED_MESSAGES);

  const togglePopover = () => {
    setIsPopoverShown(!isPopoverShown);
  };

  const customMessagePopoverProps = {
    setIsPopoverShown,
    customMessageInput,
    setCustomMessageInput,
    messages,
    setMessages,
  };

  const sendFirstMessageInTheList = () => {
    if (messages.length) {
      dispatch(mutateMessage(messages[0]));
    }
  };

  useHotKeysOnKeyup(HOT_KEYS_MAP.SEND_FIRST_MESSAGE_IN_THE_LIST, sendFirstMessageInTheList, [messages]);

  return (
    <>
      <button className={`${styles['btn']} ${styles['secondary-yellow']}`} onClick={togglePopover}>
        <span>Custom message</span>
      </button>
      {isPopoverShown && <CustomMessagePopover {...customMessagePopoverProps} />}
    </>
  );
}
