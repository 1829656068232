import { useState } from 'react';

import { LOTS_TO_DISPLAY_IN_CAROUSEL } from 'components/left-panel/catalog-carousel/constants';

import { SearchLotPopover } from './search-lot-popover';
import { ISearchNextPrevProps } from './types';

import styles from './search-next-prev.module.scss';

export function SearchNextPrev({ setCarouselOffset, currentLotIndex, totalLots }: ISearchNextPrevProps) {
  const [isSearchPopoverOpen, setIsSearchPopoverOpen] = useState(false);

  const handleSearchPopoverToggle = () => setIsSearchPopoverOpen(prevState => !prevState);

  const handleOffsetIncrement = () => {
    let maxOffset = totalLots - currentLotIndex - 1;
    if (currentLotIndex === 0) {
      maxOffset = totalLots - LOTS_TO_DISPLAY_IN_CAROUSEL + 1;
    } else if (currentLotIndex === totalLots - 1) {
      maxOffset = 1;
    }
    setCarouselOffset(prev => (prev + 1 < maxOffset ? prev + 1 : prev));
  };

  const handleOffsetDecrement = () => {
    let minOffset = -currentLotIndex;
    if (currentLotIndex === 0) {
      minOffset = -1;
    } else if (currentLotIndex === totalLots - 1) {
      minOffset = -currentLotIndex + 1;
    }
    setCarouselOffset(prev => (prev - 1 > minOffset ? prev - 1 : prev));
  };

  return (
    <div className={styles['search-next-prev']}>
      <button type='button' className={styles['search']} onClick={handleSearchPopoverToggle} />
      <button className={styles['next']} onClick={handleOffsetIncrement} />
      <button className={styles['prev']} onClick={handleOffsetDecrement} />
      {isSearchPopoverOpen && <SearchLotPopover handleSearchPopoverToggle={handleSearchPopoverToggle} />}
    </div>
  );
}
