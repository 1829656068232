import { RESTFulService, IStandardResponse } from 'services/rest-client';

import { LIVE_SERVER_COMMANDS } from './constants';
import { getLiveServerCommandURL } from './helpers';
import * as LiveServerTypes from './types';

/**
 * LiveServerService service is inherited from RESTFulService and
 * is responsible for making live server calls.
 */
export class LiveServerService extends RESTFulService {
  private readonly catalogRef;
  private readonly basePOSTBody: LiveServerTypes.ILiveServerPOSTBodyBase;

  /**
   * sets base POST body and catalogRef to be used in live server POST Calls
   * @param catalogRef
   * @param sellerId
   * @param user
   */
  constructor(catalogRef: string, sellerId: string, user: string) {
    super();
    this.catalogRef = catalogRef;
    this.basePOSTBody = { user: user, sellerId: sellerId };
  }

  /**
   * Contains connect POST call
   * @param connectData
   */
  getAuctionInfo = async (connectData: LiveServerTypes.IConnectPayload): Promise<IStandardResponse> => {
    const { houseId, userId } = connectData;
    const connectURL = getLiveServerCommandURL(this.catalogRef, LIVE_SERVER_COMMANDS.CONNECT, 'v2=true');

    return await this.post(connectURL, {
      ...this.basePOSTBody,
      houseId,
      userId,
    });
  };

  /**
   * Contains lot POST call
   * @param lotData
   */
  lot = async (lotData: LiveServerTypes.ILotPayload): Promise<IStandardResponse> => {
    const { lotId } = lotData;
    const lotURL = getLiveServerCommandURL(this.catalogRef, LIVE_SERVER_COMMANDS.LOT);

    return await this.post(lotURL, {
      ...this.basePOSTBody,
      lotId,
    });
  };

  /**
   * Contains bidAccept POST call
   * @param bidAcceptData
   */
  bidAccept = async (bidAcceptData: LiveServerTypes.IBidAcceptPayload): Promise<IStandardResponse> => {
    const { approvalId, lotId, bid, bidType, message } = bidAcceptData;
    const bidAcceptURL = getLiveServerCommandURL(this.catalogRef, LIVE_SERVER_COMMANDS.BID_ACCEPT);

    return await this.post(bidAcceptURL, {
      ...this.basePOSTBody,
      lotId,
      approvalId,
      bid,
      bidType,
      message,
    });
  };

  /**
   * Contains undoBid POST call
   * @param undoBidData
   */
  undoBid = async (undoBidData: LiveServerTypes.IUndoBidPayload): Promise<IStandardResponse> => {
    const { lotId, message } = undoBidData;
    const undoBidURL = getLiveServerCommandURL(this.catalogRef, LIVE_SERVER_COMMANDS.UNDO_BID);

    return await this.post(undoBidURL, {
      ...this.basePOSTBody,
      lotId,
      message,
    });
  };

  /**
   * Contains message POST call
   * @param messageData
   */
  message = async (messageData: LiveServerTypes.IMessagePayload): Promise<IStandardResponse> => {
    const { message } = messageData;
    const messageURL = getLiveServerCommandURL(this.catalogRef, LIVE_SERVER_COMMANDS.MESSAGE);

    return await this.post(messageURL, {
      ...this.basePOSTBody,
      message,
    });
  };

  /**
   * Contains nextUnsold POST call
   * @param nextUnsoldData
   */
  nextUnsold = async (nextUnsoldData: LiveServerTypes.INextUnsoldPayload): Promise<IStandardResponse> => {
    const { message } = nextUnsoldData;
    const nextUnsoldURL = getLiveServerCommandURL(this.catalogRef, LIVE_SERVER_COMMANDS.NEXT_UNSOLD);

    return await this.post(nextUnsoldURL, {
      ...this.basePOSTBody,
      message,
    });
  };

  /**
   * Contains startAuction POST call
   * @param startAuctionData
   */
  startAuction = async (startAuctionData: LiveServerTypes.IStartAuctionPayload): Promise<IStandardResponse> => {
    const { message } = startAuctionData;
    const startAuctionURL = getLiveServerCommandURL(this.catalogRef, LIVE_SERVER_COMMANDS.START);

    return await this.post(startAuctionURL, {
      ...this.basePOSTBody,
      message: message,
    });
  };

  /**
   * Contains increment POST call
   * @param incrementData
   */
  increment = async (incrementData: LiveServerTypes.IIncrementPayload): Promise<IStandardResponse> => {
    const { lotId, increment } = incrementData;
    const incrementURL = getLiveServerCommandURL(this.catalogRef, LIVE_SERVER_COMMANDS.INCREMENT);

    return await this.post(incrementURL, {
      ...this.basePOSTBody,
      lotId,
      increment,
    });
  };

  /**
   * Contains ask POST call
   * @param askData
   */
  ask = async (askData: LiveServerTypes.IAskPayload): Promise<IStandardResponse> => {
    const { lotId, ask } = askData;
    const askURL = getLiveServerCommandURL(this.catalogRef, LIVE_SERVER_COMMANDS.ASK);

    return await this.post(askURL, {
      ...this.basePOSTBody,
      lotId,
      ask,
    });
  };

  /**
   * Contains stop POST call
   * @param stopData
   */
  stop = async (stopData: LiveServerTypes.IStopAuctionPayload): Promise<IStandardResponse> => {
    const { message } = stopData;
    const stopURL = getLiveServerCommandURL(this.catalogRef, LIVE_SERVER_COMMANDS.STOP);

    return await this.post(stopURL, {
      ...this.basePOSTBody,
      message,
    });
  };

  /**
   * Contains sell POST call
   * @param sellData
   */
  sell = async (sellData: LiveServerTypes.ISellPayload): Promise<IStandardResponse> => {
    const { lotId, message } = sellData;
    const sellURL = getLiveServerCommandURL(this.catalogRef, LIVE_SERVER_COMMANDS.SELL);

    return await this.post(sellURL, {
      ...this.basePOSTBody,
      lotId,
      message,
    });
  };

  /**
   * Contains undoSell POST call
   * @param undoSellData
   */
  undoSell = async (undoSellData: LiveServerTypes.IUndoSellPayload): Promise<IStandardResponse> => {
    const { lotId } = undoSellData;
    const undoSellURL = getLiveServerCommandURL(this.catalogRef, LIVE_SERVER_COMMANDS.UNDO_SELL);

    return await this.post(undoSellURL, {
      ...this.basePOSTBody,
      lotId,
    });
  };

  /**
   * Contains close POST call
   * @param closeData
   */
  close = async (closeData: LiveServerTypes.ICloseAuctionPayload): Promise<IStandardResponse> => {
    const { message } = closeData;
    const closeURL = getLiveServerCommandURL(this.catalogRef, LIVE_SERVER_COMMANDS.CLOSE);

    return await this.post(closeURL, {
      ...this.basePOSTBody,
      message,
    });
  };
}
