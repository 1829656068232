import { useEffect, useState } from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { USER_CONNECTION_STATUS } from 'components/constants';
import { selectConnectionStatus } from 'redux/slices/broker/selectors';

import { ALERT_CONFIG, CONNECTED_TOAST_DURATION_MS } from './constants';

import styles from './alert.module.scss';

export function Alert() {
  const [isConnectedToastShown, setIsConnectedToastShown] = useState(false);
  const connectionStatus = useSelector(selectConnectionStatus);
  const alertConfig = ALERT_CONFIG[connectionStatus];
  const alertClasses = classNames(styles['seller-console-alert'], styles[alertConfig?.class], {
    [styles['hidden']]: !isConnectedToastShown,
  });

  useEffect(() => {
    if (connectionStatus === USER_CONNECTION_STATUS.CONNECTED) {
      setIsConnectedToastShown(true);
      setTimeout(() => {
        setIsConnectedToastShown(false);
      }, CONNECTED_TOAST_DURATION_MS);
    }
  }, [connectionStatus]);

  return (
    <div className={alertClasses}>
      <p>{alertConfig?.text}</p>
      <div className={styles['spinner']} />
    </div>
  );
}
