import { TBrokerDataItem } from '../broker/types';

export type TUpdateLotIncrementSetPayload = {
  incrementSet: number;
};

export type TUpdateLotAcceptedBidderPayload = {
  lotNumber: string;
  acceptedBidder: string;
};

export type TUpdateLotAcceptedPaddlePayload = {
  lotNumber: string;
  paddle: string;
};

export type TUpdateSoldUnsoldCatalogLotPayload = {
  eventType?: string;
  items: TBrokerDataItem[];
};

export enum BIDDER_TYPE {
  INTERNET = 'internet',
  FLOOR = 'floor',
}
