import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { HigherBidModal } from 'components/right-panel/pass-sell-lot/higher-bid-modal';
import { BID_TYPES } from 'components/right-panel/types';
import { useModal } from 'hooks/useModal';
import { mutateSell, mutateUndoSell } from 'redux/middlewares/live-server/actions';
import { selectCurrentBid, selectSubmittedBid, selectSubmittedBidType } from 'redux/slices/broker/selectors';
import { selectCurrentLot } from 'redux/slices/catalog/selectors';

import { IReopenSoldLotPopoverProps } from './types';

import styles from './reopen-sold-lot-popover.module.scss';

export function ReopenSoldLotPopover({
  lotNumber,
  showReopenSoldLotPopover,
  isSearchView,
  handleReopenSoldPopoverCancel,
}: IReopenSoldLotPopoverProps) {
  const dispatch = useDispatch();
  const currentLot = useSelector(selectCurrentLot);
  const currentBid = useSelector(selectCurrentBid);
  const submittedBid = useSelector(selectSubmittedBid);
  const submittedBidType = useSelector(selectSubmittedBidType);
  const showSellLotBtn = +currentBid > 0 && !currentLot?.isSold;
  const {
    isOpenModal: isHigherBidModalOpen,
    openModal: openHigherBidModal,
    closeModal: closeHigherBidModal,
  } = useModal();

  const popoverContainerClasses = classNames(styles['reopen-sold-lot-popover'], {
    [styles['search-view']]: isSearchView,
  });

  const handleCloseHigherBidModal = () => {
    handleReopenSoldPopoverCancel();
    closeHigherBidModal();
  };

  const handleSell = () => {
    dispatch(mutateSell({ nextAction: mutateUndoSell(lotNumber), triggerNextActionOnSuccessfulSell: true }));
    handleReopenSoldPopoverCancel();
  };

  const handlePassReopen = () => {
    dispatch(mutateUndoSell(lotNumber));
    handleReopenSoldPopoverCancel();
  };

  const handleSellReopen = () => {
    if (submittedBid > currentBid && submittedBidType !== BID_TYPES.BID_FLOOR) {
      openHigherBidModal();
      return;
    }
    handleSell();
  };

  return (
    <>
      {showReopenSoldLotPopover && (
        <div className={popoverContainerClasses}>
          <div className={styles['reopen-sold-lot']}>
            <p>Reopening a lot will permanently erase all previous bids and bidder data for that lot.</p>
            <p>Are you sure you want to continue?</p>
            <div className={styles['button-list']}>
              {showSellLotBtn && (
                <button onClick={handleSellReopen} className={`${styles['btn']} ${styles['gray-dark']}`}>
                  {`Sell Lot ${currentLot?.lotNumber} / Reopen Lot ${lotNumber}`}
                </button>
              )}
              {currentLot?.isSold ? (
                <button onClick={handlePassReopen} className={`${styles['btn']} ${styles['gray-dark']}`}>
                  {`Reopen Lot ${lotNumber}`}
                </button>
              ) : (
                <button onClick={handlePassReopen} className={`${styles['btn']} ${styles['gray-dark']}`}>
                  {`Pass Lot ${currentLot?.lotNumber} / Reopen Lot ${lotNumber}`}
                </button>
              )}
              <button onClick={handleReopenSoldPopoverCancel} className={`${styles['btn']} ${styles['green']}`}>
                Cancel
              </button>
            </div>
          </div>
          <button onClick={handleReopenSoldPopoverCancel} className={styles['close-popover-btn']} />
          {isHigherBidModalOpen && (
            <HigherBidModal handleSell={handleSell} closeHigherBidModal={handleCloseHigherBidModal} />
          )}
        </div>
      )}
    </>
  );
}
