import { useDispatch, useSelector } from 'react-redux';

import { STATIC_MESSAGES } from 'components/constants';
import { useSellNext } from 'hooks/use-sell-next';
import { useModal } from 'hooks/useModal';
import { mutateNextUnsold } from 'redux/middlewares/live-server/actions';
import { brokerActions } from 'redux/slices/broker';
import {
  selectCurrentAuctionState,
  selectCurrentBid,
  selectCurrentLotNumber,
  selectSubmittedBid,
  selectSubmittedBidType,
  selectIsSellCallInProgress,
} from 'redux/slices/broker/selectors';
import { AUCTION_STATES } from 'redux/slices/broker/types';

import { BID_TYPES } from '../types';
import { HigherBidModal } from './higher-bid-modal';

import styles from './pass-sell-lot.module.scss';

export function PassSellLot() {
  const dispatch = useDispatch();
  const {
    isOpenModal: isHigherBidModalOpen,
    openModal: openHigherBidModal,
    closeModal: closeHigherBidModal,
  } = useModal();
  const currentAuctionState = useSelector(selectCurrentAuctionState);
  const currentLotNumber = useSelector(selectCurrentLotNumber);
  const currentBid = useSelector(selectCurrentBid);
  const submittedBid = useSelector(selectSubmittedBid);
  const submittedBidType = useSelector(selectSubmittedBidType);
  const isSellCallInProgress = useSelector(selectIsSellCallInProgress);
  const sellNext = useSellNext();

  const onPassNextClick = () => {
    if (currentAuctionState === AUCTION_STATES.IN_PROGRESS) {
      dispatch(mutateNextUnsold(`Lot ${currentLotNumber} passed`));
    } else {
      dispatch(brokerActions.displayCustomMessage({ message: STATIC_MESSAGES.START_AUCTION_FIRST }));
    }
  };

  const onSellNextClick = () => {
    if (currentAuctionState === AUCTION_STATES.PAUSED) {
      dispatch(brokerActions.displayCustomMessage({ message: STATIC_MESSAGES.START_AUCTION_FIRST }));
    } else if (submittedBid > currentBid && submittedBidType !== BID_TYPES.BID_FLOOR) {
      openHigherBidModal();
    } else {
      sellNext();
    }
  };

  return (
    <div className={styles['pass-sell-lot']}>
      <button className={styles['pass-btn']} onClick={onPassNextClick} />
      <button className={styles['sell-btn']} onClick={onSellNextClick} disabled={isSellCallInProgress} />
      {isHigherBidModalOpen && <HigherBidModal closeHigherBidModal={closeHigherBidModal} />}
    </div>
  );
}
