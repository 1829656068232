import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BID_EVENTS } from 'redux/middlewares/socket/types';
import { EVENT_DATA_ITEM_STATES } from 'redux/slices/broker/types';
import { ICatalog } from 'redux/types';
import { TLot } from 'types';

import { fetchCatalogRefFromURL, defaultLotValues, getAcceptedBidder } from './helpers';
import { fetchCatalog } from './thunks';
import {
  TUpdateLotAcceptedBidderPayload,
  TUpdateLotAcceptedPaddlePayload,
  TUpdateLotIncrementSetPayload,
  TUpdateSoldUnsoldCatalogLotPayload,
} from './types';

const INITIAL_STATE: ICatalog = {
  title: '',
  currencyCode: '',
  catalogRef: fetchCatalogRefFromURL(),
  lots: new Map<string, TLot>(),
  isLotsFetched: false,
  totalSoldLots: 0,
  incrementSet: -1,
  incrementSetOnServer: -1,
  errors: {
    callFailure: false,
  },
};

const catalogSlice = createSlice({
  name: 'catalog',
  initialState: INITIAL_STATE,
  reducers: {
    updateSoldUnsoldCatalogLot: (state, action) => {
      const { items, eventType } = action.payload as TUpdateSoldUnsoldCatalogLotPayload;
      let counter = 0;
      for (const item of items) {
        const lotData = state.lots.get(item.lotNumber) || defaultLotValues;
        const isSoldPreviousState = lotData.isSold;
        const isSoldNewState = item.itemState === EVENT_DATA_ITEM_STATES.SOLD;

        if (isSoldPreviousState !== isSoldNewState) {
          isSoldPreviousState && !isSoldNewState ? counter-- : counter++;
        }
        const soldAmountAndIsSoldUpdate = {
          isSold: isSoldNewState,
          soldAmount: +item.currentBid,
        };

        if (eventType === BID_EVENTS.EVENT) {
          state.lots.set(item.lotNumber, {
            ...lotData,
            ...soldAmountAndIsSoldUpdate,
            acceptedBidder: getAcceptedBidder(item.currentWinner),
            paddle: item.paddle,
          });
        } else {
          state.lots.set(item.lotNumber, {
            ...lotData,
            ...soldAmountAndIsSoldUpdate,
          });
        }
      }
      state.totalSoldLots += counter;
    },
    updateLotIncrementSetAndBackup: (state, action) => {
      const { incrementSet } = action.payload as TUpdateLotIncrementSetPayload;
      state.incrementSet = Number(incrementSet);
      state.incrementSetOnServer = Number(incrementSet);
    },
    updateLotIncrementSet: (state, action) => {
      state.incrementSet = Number(action.payload);
    },
    updateLotAcceptedBidder: (state, action) => {
      const { lotNumber, acceptedBidder } = action.payload as TUpdateLotAcceptedBidderPayload;
      const lotData = state.lots.get(lotNumber);
      if (lotData) lotData.acceptedBidder = acceptedBidder;
    },
    updateLotAcceptedPaddle: (state, action) => {
      const { lotNumber, paddle } = action.payload as TUpdateLotAcceptedPaddlePayload;
      const lotData = state.lots.get(lotNumber);
      if (lotData) lotData.paddle = paddle;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchCatalog.fulfilled, (state, { payload }: PayloadAction<any>) => {
      const { title, currencyCode, lots } = payload || {};
      state.title = title;
      state.currencyCode = currencyCode;
      for (let i = 0; i < lots.length; i++) {
        const lot = lots[i];
        const lotData = state.lots.get(lot.lotNumber) || defaultLotValues;
        state.lots.set(lot.lotNumber, {
          ...lotData,
          ...lot,
          orderIndex: i,
        });
      }
      state.isLotsFetched = true;
    });
    builder.addCase(fetchCatalog.rejected, state => {
      state.errors.callFailure = true;
    });
  },
});

export const {
  updateSoldUnsoldCatalogLot,
  updateLotIncrementSetAndBackup,
  updateLotIncrementSet,
  updateLotAcceptedBidder,
  updateLotAcceptedPaddle,
} = catalogSlice.actions;
export { fetchCatalog } from './thunks';
export const catalogReducer = catalogSlice.reducer;
