import { CatalogCarousel } from './catalog-carousel';
import { CurrentLotInfo } from './current-lot-info';
import { LiveMessages } from './live-messages';
import { ProgressBar } from './progress-bar';
import { StartStopPause } from './start-stop-pause';

import styles from './left-panel.module.scss';

export function LeftPanel() {
  return (
    <div className={styles['left-panel']}>
      <CurrentLotInfo />
      <LiveMessages />
      <StartStopPause />
      <ProgressBar />
      <CatalogCarousel />
    </div>
  );
}
