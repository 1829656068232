/**
 * A utility function to get the reordered messages
 * @param messages: list of messages to be ordered
 * @param sourceIndex: index to be moved from
 * @param destinationIndex: index to be placed at
 * @returns: returns reordered list of messages
 */
export const reorderMessages = (messages: string[], sourceIndex: number, destinationIndex: number) => {
  const orderedMessages = [...messages];
  const [removed] = orderedMessages.splice(sourceIndex, 1);
  orderedMessages.splice(destinationIndex, 0, removed);
  return orderedMessages;
};

/**
 * A utility function to remove the message at index from the list
 * @param messages: list of messages
 * @param index: index to remove message from
 * @returns: returns a list of messages without the message at index
 */
export const removeMessageAtIndex = (messages: string[], index: number) => messages.filter((_, i) => i !== index);
