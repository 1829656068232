import { BID_TYPES } from '../types';

/**
 * A helper that returns true if the ask price is lowered after bid submission.
 * We are particularly checking askPrice and bid(submittedBid) here to handle the scenario if a user
  submits an online bid and then lowered the ask price, the ask event that we receive still have the
  submittedBid and approvalId of the user who submitted the bid.
  Currently, backend is not handling this case, that's why we still have the submittedBid and approvalId in the new ask event.
  So this particular helper use to check that if ask price lower than bid it means askPrice was lowered after the bid submission
  and we dont want to show that as user will need to bid again.
 * @param askPrice ask price
 * @param bid bid amount
 * @param bidType bid type
 */
export const isAskPriceLoweredAfterBidSubmission = (bid: number, bidType: string, askPrice: number) =>
  bidType === BID_TYPES.BID_INTERNET && askPrice < bid;

/**
 * A helper that returns true if internet bid button is enabled
 * It return true if bidType is either BID_TYPES.BID_INTERNET(online bid) or BID_TYPES.BID_ORDER(absentee bid)
 * and askPrice is greater or equal to submitted bid
 * @param askPrice ask price
 * @param bid bid amount
 * @param bidType bid type
 * @param approvalId
 */
export const isInternetBidEnabled = (bid: number, bidType: string, askPrice: number, approvalId: string) =>
  !!(
    askPrice &&
    approvalId &&
    bid &&
    bidType &&
    !isAskPriceLoweredAfterBidSubmission(bid, bidType, askPrice) &&
    (bidType === BID_TYPES.BID_INTERNET || bidType === BID_TYPES.BID_ORDER)
  );
