import { useMemo, useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { LotCard } from 'components/common';
import { selectIsSocketConnected } from 'redux/slices/broker/selectors';
import { selectCatalogLots, selectIsLotsFetched, selectCurrentLot } from 'redux/slices/catalog/selectors';

import { getKeysOfLotsToDisplay } from './helpers';
import { SearchNextPrev } from './search-next-prev';

import styles from './catalog-carousel.module.scss';

export function CatalogCarousel() {
  const lots = useSelector(selectCatalogLots);
  const isLotsFetched = useSelector(selectIsLotsFetched);
  const isSocketConnected = useSelector(selectIsSocketConnected);
  const currentLot = useSelector(selectCurrentLot);

  const [carouselOffset, setCarouselOffset] = useState(0);

  useEffect(() => setCarouselOffset(0), [currentLot?.lotNumber]);

  /* We are memoizing the lot keys to prevent the creation of an array on each re-render
  isSocketConnected and isLotsFetched are used as dependencies to avoid creating a new array once all
  the related data is fetched */
  const lotKeys = useMemo(() => Array.from(lots.keys()), [isLotsFetched, isSocketConnected]);

  const currentLotIndex = currentLot ? currentLot.orderIndex : -1;
  const lotsToDisplayKeys = getKeysOfLotsToDisplay(lotKeys, currentLotIndex, carouselOffset);
  const lotsToDisplay = lotsToDisplayKeys.map(key => lots.get(key));

  return (
    <div className={styles['catalog-carousel-section']}>
      <div className={styles['catalog-carousel']}>
        <ul>
          {lotsToDisplay.map(
            lot =>
              lot && (
                <li key={lot.lotID}>
                  <LotCard lot={lot} isCurrent={lot.lotNumber === currentLot?.lotNumber} />
                </li>
              ),
          )}
        </ul>
      </div>
      <SearchNextPrev setCarouselOffset={setCarouselOffset} currentLotIndex={currentLotIndex} totalLots={lots.size} />
    </div>
  );
}
