import { BID_TYPES } from 'components/right-panel/types';

export enum LIVE_SERVER_ACTIONS {
  AUCTION_START = 'auctionStart',
  BID_ACCEPT = 'bidAccept',
  NEXT_UNSOLD = 'nextUnsold',
  UNDO_BID = 'undoBid',
  LOT = 'lot',
  INCREMENT = 'increment',
  ASK = 'ask',
  STOP = 'stop',
  MESSAGE = 'message',
  SELL = 'sell',
  CLOSE = 'close',
  UNDO_SELL = 'undoSell',
}

export interface IActionPayload {
  type: LIVE_SERVER_ACTIONS;
  payload?: string;
}

export interface ISellPayload {
  nextAction?: IActionPayload;
  triggerNextActionOnSuccessfulSell?: boolean;
}

export interface IBidAcceptPayload {
  bidType: BID_TYPES;
  bid: number;
}

export interface ILiveServerResponse {
  incrementSet?: number;
  acceptedBidder?: string;
  winningBidder?: string;
}
