import { TLot } from 'types';

export interface ICatalog {
  title: string;
  currencyCode: string;
  catalogRef: string;
  lots: Map<string, TLot>;
  isLotsFetched: boolean;
  totalSoldLots: number;
  incrementSet: number;
  incrementSetOnServer: number;
  errors: {
    callFailure: boolean;
  };
}

export enum BIDDER_TYPES_TO_DISPLAY {
  INTERNET_BIDDER = 'Internet Bidder',
  FLOOR_BIDDER = 'Floor Bidder',
}
