import { SellerConsoleModal } from 'components/common/seller-console-modal';

import { IStopAuctionModal } from './types';

import styles from './stop-auction-modal.module.scss';

export const StopAuctionModal = ({ closeModal, closeAuction }: IStopAuctionModal) => {
  return (
    <SellerConsoleModal
      containerClass={styles['stop-auction-modal']}
      title='Close Auction'
      description={
        <>
          <p>{`By clicking "Close Auction" below you agree that:`}</p>
          <ul>
            <li>The auction is complete.</li>
            <li>Closing an auction is irreversible.</li>
            <li>All transactions will be recorded as FINAL.</li>
          </ul>
          <strong>This action cannot be undone.</strong>
        </>
      }
      primaryBtnText='Return to Auction'
      primaryBtnClass='green'
      primaryBtnClickHandler={closeModal}
      secondaryBtnText='Close Auction'
      secondaryBtnClass='gray-dark'
      secondaryBtnClickHandler={closeAuction}
    />
  );
};
